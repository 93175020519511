@import "./bootstrap.css";
@import "./nifty.css";
@import "./illustration-style.css";
@import "./ag-grid-custom.css";

/* @font-face {
  font-family: "ProximaBold";
  src: url("../fonts/zero-font/");
} */

/*
env vars from https://stackoverflow.com/questions/63531281/detect-whether-an-ios-device-has-a-home-bar-using-js-or-css
*/
body {
  margin: 0;
  padding: 0;
  --sat: env(safe-area-inset-top, 0);
  --sar: env(safe-area-inset-right, 0);
  --sab: env(safe-area-inset-bottom, 0);
  --sal: env(safe-area-inset-left, 0);
  --zero-blue: #1d2a35;
  --zero-dark-grey: #505050;
  --zero-gold: #d6b17e;
  --zero-light-blue: #03a9f4;
  --zero-grey: #7a878e;
  --zero-border-color: #e2e2e2;
  --zero-light-border-color: #ededed;
  --zero-delete-red: #ee0808;
  --zero-fonts: "Segoe UI", Tahoma, Geneva, Verdana, Trebuchet, Arial, Times,
    sans-serif, FontAwesome, "Apple Color Emoji", "Segoe UI Emoji",
    "Noto Color Emoji" !important;
  --contracted-post-max-height: 350px;
}

body.ant-scrolling-effect {
  /* overflow: unset !important; */
}

.zero-blue-background {
  background-color: #1d2a35 !important;
}

.zero-blue {
  color: #1d2a35 !important;
}

.zero-light-blue {
  color: #03a9f4 !important;
}

.zero-grey {
  color: #7a878e !important;
}

.zero-dark-grey {
  color: #505050 !important;
}

.zero-muted-grey {
  color: #8493a0 !important;
}

.zero-gold-background {
  background-color: #d6b27e !important;
}

.zero-gold {
  color: #d6b27e !important;
}

.zero-plat-background {
  background-color: #97a9b9 !important;
}

.zero-plat {
  color: #97a9b9 !important;
}

.zero-bronze-background {
  background-color: #ae927d !important;
}

.zero-bronze {
  color: #ae927d !important;
}

.zero-orange-background {
  background-color: #da8a67;
}

.zero-orange {
  color: #da8a67;
}

.zero-delete-red {
  color: var(--zero-delete-red) !important;
}

.mar-btm-10 {
  margin-bottom: 10px;
}

.mar-btm-15 {
  margin-bottom: 15px;
}

.mar-btm-0 {
  margin-bottom: 0px;
}

.mar-btm-5 {
  margin-bottom: 5px;
}

.mar-top-0 {
  margin-top: 0px;
}

.mar-top-5 {
  margin-top: 5px;
}

.mar-top-10 {
  margin-top: 10px;
}

.mar-top-15 {
  margin-top: 15px;
}

.mar-lft-5 {
  margin-left: 5px;
}

.mar-lft-10 {
  margin-left: 10px;
}

.mar-rgt-5 {
  margin-right: 5px;
}

.mar-rgt-10 {
  margin-right: 10px;
}

.mar-y-10 {
  margin-top: 10px;
  margin-bottom: 10px;
}

.panel-body.pad-15 {
  padding: 15px !important;
}

.pad-15 {
  padding: 1.5rem;
}

.pad-x-15 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.pad-y-5 {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.pad-5 {
  padding: 0.5rem;
}

.pad-10 {
  padding: 1rem;
}

.pad-20 {
  padding: 2rem;
}

.pad-30 {
  padding: 3rem;
}

.pad-top-0 {
  padding-top: 0;
}

.panel.panel-border {
  border: 1px solid #e2e2e2;
  border-radius: 3px;
}

.panel.panel-zero {
  padding: 2rem;
  margin: 0;
}

.panel.panel-top-margin {
  margin-top: 1rem;
}

.page-title {
  color: var(--zero-blue);
  margin: 0;
}

.page-description {
  color: var(--zero-blue);
  margin: 0;
  padding: 0 0.2rem;
}

.offline-sidebar-mask {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.5);
  z-index: 1000;
  cursor: not-allowed;
}

.offline-sidebar-header {
  position: relative;
  text-align: center;
  z-index: 1001;
  background-color: #e75c50;
  color: white;
  font-weight: 600;
  padding: 1rem 0;
}

body:not(.initializing),
div#content-container {
  background-color: #f6f6f6;
}

body,
div#content-container {
  padding-top: 0 !important;
  font-family: var(--zero-fonts);
}

.fixed-fluid [class*="fixed-"] {
  background-color: #f6f6f6 !important;
}

.fixed-sm-250.pull-sm-left.file-sidebar.white {
  background-color: white !important;
}

#container.mainnav-fixed:not(.navbar-fixed) #mainnav-container.affix {
  top: 0px;
}

#content-container:before {
  background-color: #f6f6f6;
}

aside#aside-container {
  margin-top: 58px;
  z-index: 10000;
}

body.custom-body-scroll {
  /* overflow: hidden; */
}

.container-custom {
  height: 100vh !important;
  overflow-y: scroll !important;
}

.error-screen {
  background-image: url("./img/whale.png");
  /* background-position: center;  */
  background-size: cover;
  background-repeat: no-repeat;
}

.error-screen-msg {
  width: 50%;
}

/*
.smooth-scroll {
	-webkit-overflow-scrolling: touch !important;
} */

/* .stop-scrolling {
	overflow: hidden;
	position: fixed;
} */

.update-message {
  position: fixed;
  min-width: 360px;
  top: 2rem;
  left: 50%;
  transform: translateX(-50%);
  font-weight: 600;
  background-color: var(--zero-light-blue);
  color: white;
  padding: 1.5rem;
  z-index: 5000;

  .update-message-header {
    display: flex;
    justify-content: space-between;
  }

  .update-message-close {
    font-size: 1.6rem;
    cursor: pointer;
    padding-left: 1rem;
  }

  h3 {
    color: white;
    font-size: 1.6rem;
    margin-top: 0;
  }

  p {
    margin-bottom: 0;
    font-size: 1.4rem;
  }
}

.offline-mode {
  overflow: hidden;
}

#offline-mode-div {
  position: fixed;
  padding: 0;
  margin: 0;

  top: 0;
  left: 0;

  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.4);
  z-index: 1109;
}

nav#mainnav-container {
  background-color: white;
  padding-top: 0px;
  width: 250px;
}

.navbar-content.big {
  margin-left: 0px;
  height: 60px;
  border-bottom: 1px solid #f6f6f6;
}

.navbar-top-links > li > button,
.navbar-top-links > li > a {
  padding: 0 6px;
}

.navbar-top-links > li > a.no-hover:hover {
  background-color: unset !important;
}

#mainnav-menu ul a {
  padding-left: 35px;
}

#mainnav-menu > div > li > a > span.fa,
#mainnav-menu > div > li > a > span.fas {
  margin-right: 4px;
}

#page-content {
  max-width: 1300px;
  margin: 0 auto;
  padding: 10px;
  padding-bottom: calc(100px + var(--sab));
}

p a {
  overflow-wrap: break-word;
}

#page-head {
  padding: 10px 10px 0;
}

.mobile-footer {
  height: 50px;
  position: fixed;
}

.note-editable.panel-body {
  height: unset !important;
}

.note-editor.note-frame {
  border: solid 1px #eaeaea !important;
}

.panel-control > .input-group-wrap {
  max-width: 850px;
}

.list-group.bg-trans.search {
  margin: 10px 0px 10px 0px;
  display: flex;
  justify-content: center;
}

a.aside-toggle:hover,
a.mainnav-toggle:hover {
  cursor: pointer;
}

.hover-cursor:hover {
  cursor: pointer;
}

.link-hover:hover {
  text-decoration: underline;
  cursor: pointer;
}

div.Select {
  display: inline-block;
  width: 200px;
  margin-top: 15px;
  float: right;
}

#container.navbar-fixed > #navbar {
  position: fixed !important;
}

div#mainnav {
  background-color: white;
}

#mainnav-menu > .active {
  background-color: #1e303d;
}

div.profile-wrap.text-center {
  background-color: white;
}

#mainnav .mainnav-profile .list-group {
  background-color: #f6f6f6;
}

div#mainnav-profile.mainnav-profile {
  margin-bottom: -15px;
}

#mainnav .mainnav-profile .list-group-item {
  padding: 12px 18px !important;
}

#mainnav-menu a {
  padding: 10px 15px;
}

#mainnav-container {
  #mainnav-menu a.active-sidebar-item {
    padding-right: 10px;
  }
}

#mainnav-menu a:hover {
  background-color: #fbfbfb;
}

#mainnav-menu > .active-link > a {
  background-color: #3c576c;
}

#mainnav-menu > .active-link > a:hover {
  background-color: #364b5b;
}

/*buttons*/

.btn {
  font-weight: 600 !important;
}

.btn-discard,
.btn-discard:focus {
  background-color: transparent;
  border-color: #1d2a35 !important;
  color: #1d2a35;
}

.btn-discard:hover {
  background-color: #34495b;
  border-color: #34495b !important;
  color: white;
}

.btn-delete,
.btn-delete:focus {
  background-color: white;
  border-color: var(--zero-delete-red) !important;
  color: var(--zero-delete-red);
}

.btn-delete:hover {
  color: var(--zero-delete-red);
}

.btn-primary {
  background-color: #d6b17e;
  border-color: #d6b27e !important;
}

.btn-primary:hover:not(:disabled) {
  background-color: #caa065 !important;
  border-color: #caa065 !important;
  box-shadow: none !important;
}

.btn-primary:active:not(:disabled) {
  background-color: #d6b17e !important;
  border-color: #d6b27e !important;
}

.btn-primary:focus:not(:disabled) {
  background-color: #d6b17e !important;
  border-color: #d6b27e !important;
}

.btn-primary.dark {
  background-color: white;
  color: #1d2a35;
  border-color: #1d2a35 !important;
}

.btn-primary.dark:hover {
  border-color: #1d2a35;
  background-color: #1d2a35 !important;
  color: white;
}

.btn-primary.blue {
  background-color: #03a9f4 !important;
  border-color: #03a9f4 !important;
}

.btn-primary.blue:hover {
  background-color: #269cce !important;
  border-color: #269cce !important;
  box-shadow: none !important;
}

.btn.btn-modal {
  background-color: #d6b17e;
  border-color: #d6b27e !important;
  float: right;
  color: white;
}

.btn.btn-modal:hover {
  background-color: #caa065 !important;
  border-color: #caa065 !important;
  box-shadow: none !important;
}

.btn.btn-plain {
  border-color: #d9d9d9 !important;
  color: #1d2a35;
}

.btn.btn-plain:hover {
  border-color: #1d2a35 !important;
}

.btn-primary.profile {
  /* float: left !important;
	margin: 15px 0px 0 15px !important; */
  margin: 0 auto;
  display: block;
  text-align: center;
  max-width: 75px;
}

.round-icon-button {
  --round-icon-btn-size: 3.5rem;
  border: 1px solid #d9d9d9;
  border-radius: 50%;
  background-color: transparent;
  color: var(--zero-blue);
  font-size: 16px;
  width: var(--round-icon-btn-size);
  height: var(--round-icon-btn-size);
  display: flex;
  justify-content: center;
  align-items: center;
}

td > input {
  border: 1px solid #47abfc !important;
}

.btn-group.btn-group-toggle.form-analytics > .btn-secondary {
  box-shadow: none;
}

.btn-group.btn-group-toggle.form-analytics > .btn-secondary.active {
  background-color: #d6b27e;
  color: white;
}

div.btn-group.bootstrap-select {
  margin-bottom: unset;
}

.btn-group.bootstrap-select {
  margin-top: 15px;
  float: right !important;
}

span.badge.badge-info.badge-icon {
  background-color: #03a9f4;
  border-color: #03a9f4;
  margin-top: 5px;
}

#container .badge.badge-info.analytics,
#container .badge.badge-gray.analytics,
#container .badge.badge-primary.analytics {
  font-size: 100% !important;
  margin-right: 8px;
  min-width: 45px;
}

#container .badge.badge-gray.analytics {
  background-color: #9cacb4 !important;
}

#organswrapper svg {
  height: 500px !important;
}

/*Panels*/
.col-reduce-padding {
  padding-right: 5px !important;
  padding-left: 5px !important;
}

.col-reduce-padding-team:nth-child(odd) {
  padding-left: 5px;
  padding-right: 0px;
}

.col-reduce-padding-team:nth-child(even) {
  padding-left: 0px;
  padding-right: 5px;
}

.panel-body.no-pad {
  padding: 0px !important;
}

.panel-body.no-btm-pad {
  padding-bottom: 0px !important;
}

.panel-body.no-top-pad {
  padding-top: 0px !important;
}

.panel-body.bulletin-post {
  padding-left: 0px;
  padding-right: 0px;
  padding-bottom: 0px !important;
  padding-top: 0px !important;
}

.panel-heading.bulletin-post {
  height: auto;
  min-height: 0px;
  margin-top: 15px;
}

.panel-heading.bulletin-post > h3 {
  margin-top: 0px;
  margin-bottom: 0px;
  margin-left: 0px;
}

.panel-body.view {
  padding-bottom: 0px !important;
  padding-top: 0px !important;
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.panel-heading {
  height: unset;
  min-height: 40px;
}

.panel-heading.analytics {
  min-height: 20px;
  height: 40px;
}

.analytics-schedule-details-flex {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.panel-title {
  white-space: normal;
  line-height: 50px;
}

h2.panel-title.analytics {
  font-weight: 600;
  font-size: 17px;
  color: #1d2a35;
  /* line-height: 30px */
}

h2.panel-title.analytics:hover {
  text-decoration: none;
  cursor: default;
}

.panel > .panel-heading:after {
  border-bottom: 0;
}

.panel.article {
  border-radius: 3px;
  padding: 10px 15px 10px 15px;
}

.thin-border {
  border: solid 1px #e2e2e2;
}

.panel.thin-border {
  margin-bottom: 10px;
}

div.article-body-content a {
  color: #03a9f4 !important;
}

div.article-body-content a:hover {
  text-decoration: underline;
}

div.article-body-content img {
  max-width: 600px;
  height: auto;
  object-fit: contain;
}

.nav-header-panel {
  padding: 12px;
}

.panel.bulletin-post-border {
  border-radius: 3px;
  padding: 1rem 1.5rem;
  border: solid 1px #e2e2e2;
  max-width: 850px;
  margin: 0 auto;
}

.panel.bulletin-make-border {
  border-radius: 3px;
  padding: 10px 15px 10px 15px;
  border: solid 1px #e2e2e2;
  max-width: 850px;
  margin: 0 auto;
}

.panel-heading.update {
  min-height: 20px;
}

.panel-heading.article.auto {
  height: auto !important;
}

.panel-heading.article:after {
  border-bottom: 0px solid;
}

div.panel-body.table {
  padding: 15px 20px 15px 20px;
}

#container table.table {
  thead.zero-blue th {
    color: var(--zero-blue);
  }

  tbody td {
    color: unset;
  }
}

thead.no-thead-borders > tr > th {
  border: none;
}

a.updated-by {
  color: rgb(29, 130, 237);
  text-decoration: unset;
}

a.updated-by:hover {
  color: rgb(29, 130, 237);
  cursor: pointer;
  text-decoration: underline;
}

.timeline::before {
  left: 23px;
  bottom: 20px;
}

.timeline::after {
  left: 20px;
}

.timeline.closed::before {
  /* bottom: 110px; */
}

.timeline.closed::after {
  display: none;
}

.timeline-stat {
  width: 50px;
}

.timeline-icon {
  color: #1d2a35;
  font-size: 20px;
}

.timeline-label {
  background-color: #f6f6f6 !important;
  margin-left: 55px !important;
}

.timeline-label:after {
  border-right-color: #f6f6f6 !important;
}

@media screen and (max-width: 479px) {
  .timeline.timeline-small {
    &::before {
      left: 8px;
    }

    &::after {
      left: 5px;
    }

    .timeline-stat,
    .timeline-icon {
      width: 20px;
      height: 40px;
      line-height: 40px;
      font-size: 16px;
    }

    .timeline-label {
      margin-left: 35px !important;
    }
  }
}

/*Labels*/

.label-primary {
  font-size: 100%;
  color: white;
  background-color: #1d2a35;
}

.label-table {
  font-weight: bold;
  font-size: 1.2rem;
  padding: 7px;
  width: 70%;
  border-radius: 5px;
}

.label.label-primary.tags {
  font-size: 85%;
  float: left;
  display: inline-block;
  margin: 0px 10px 0 0;
  padding: 6px 12px 6px 12px;
}

.pager li > span {
  border: 1px solid #e2e2e2 !important;
}

.pager li > span:hover {
  cursor: pointer;
}

/*Breadcrumb*/

ol.breadcrumb {
  font-size: 100%;
  color: white !important;
  text-decoration: unset !important;
  text-align: center !important;
  display: block !important;
  margin: 0 auto !important;
  padding-top: 8px;
  padding-bottom: 0px;
}

.breadcrumb > .active {
  font-weight: 700;
  color: white !important;
  text-decoration: unset;
}

.breadcrumb > .active.admin {
  color: #8f9ea6 !important;
}

.breadcrumb > li.parent > .active {
  text-decoration: unset !important;
  font-weight: 500;
}

.breadcrumb > li + li:before {
  margin-right: 8px;
  margin-left: 8px;
}

/*Lists*/

.truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.truncate.email {
  max-width: 30ch;
}

.truncate.name {
  max-width: 20ch;
}

.truncate.name.long {
  max-width: 40ch;
}

.truncate.title {
  max-width: 30ch;
}

p.menu-title {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 30ch;
  margin-bottom: 0px;
}

li i.arrow {
  margin-top: 6px;
}

li.bulletpoint {
  font-size: 100%;
  color: #1d2a35;
}

.list-group-item {
  background-color: unset !important;
  border-style: none !important;
}

.dropdown-toggle.open {
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
  /* margin-bottom: 2px; */
}

.dropdown-menu:not(.head-list) > li > a:hover {
  background-color: #03a9f4 !important;
  border-color: #03a9f4 !important;
}

.chosen-container-multi .chosen-choices li.search-choice {
  background-color: #1d2a35 !important;
  border-color: #1d2a35 !important;
}

.chosen-container .chosen-results li.highlighted {
  background-color: #03a9f4 !important;
  border-color: #03a9f4 !important;
}

#container.aside-bright #aside a:not(.search-choice-close):hover {
  background-color: #f6f6f6 !important;
}

#dashboard li.active button:hover,
#container.aside-bright #aside li.active button:hover,
#container.aside-bright #aside li.active a:hover {
  background-color: white !important;
}

#dashboard button,
#container.aside-bright #aside button,
#container.aside-bright #aside a {
  color: #1d2a35;
  font-weight: 600;
  width: 100%;
}

#dashboard button:hover,
#dashboard button:focus,
#container.aside-bright #aside button:hover,
#container.aside-bright #aside button:focus,
#container.aside-bright #aside a:hover,
#container.aside-bright #aside a:focus {
  color: #1d2a35;
}

#dashboard .nav-tabs li.active,
#aside-container #aside .nav-tabs li.active {
  border-bottom-color: #1d2a35;
}

#dashboard .nav-tabs .active button::before,
#dashboard .nav-tabs .active button::after,
#aside-container #aside .nav-tabs .active button::before,
#aside-container #aside .nav-tabs .active button::after,
#aside-container #aside .nav-tabs .active a::before,
#aside-container #aside .nav-tabs .active a::after {
  background: none;
}

#aside-container #aside .nav-tabs li:not(.active) button,
#aside-container #aside .nav-tabs li:not(.active) a {
  opacity: 0.5;
}

ul.toggle-tabs li {
  border-bottom: 1px solid #f9f9f9;
  display: table-cell;
  width: 1%;
}

ul.toggle-tabs li.active {
  border-bottom-color: #1d2a35;
}

ul.toggle-tabs li button {
  color: #1d2a35;
  font-weight: 600;
  width: 100%;
}

ul.toggle-tabs li:not(.active) button {
  opacity: 0.5;
}

/*Navigation/Menu*/

#container.mainnav-sm #navbar .navbar-brand {
  width: unset;
}

#container.mainnav-lg .navbar-brand {
  width: unset;
}

#container.mainnav-lg #footer {
  padding-left: 0px;
}

.nav-tabs li a {
  border-radius: 3px 3px 0 0;
  font-weight: bold;
}

.navbar-header {
  background-color: white;
}

.navbar-header:before {
  background-color: white;
}

#mainnav-container {
  background-color: white;
}

.custom-search {
  border-left: 1px solid #d9d9d9;
  border-top: 1px solid #d9d9d9;
  border-bottom: 1px solid #d9d9d9;
  border-right: 0px solid #d9d9d9;
  /* border-top-left-radius: 2px;
	border-bottom-left-radius: 2px */
  /* color:#BFBFBF; */
}

.custom-search:focus {
  border-right: 1px solid #47abfc;
  border-left: 1px solid #47abfc;
  border-top: 1px solid #47abfc;
  border-bottom: 1px solid #47abfc;
}

input.form-control.custom-search::placeholder {
  color: #bfbfbf !important;
  font-size: 14px;
}

input.form-control.error {
  border-color: tomato;
  background-color: #fff5f5;
}

.ButtonLink {
  background-color: transparent;
  border: 1px solid transparent;
  cursor: pointer;
  padding: 0px;
}

.ButtonLink-hover-underline:hover,
.ButtonLink-hover-underline:focus {
  text-decoration: underline;
}

button.reaction {
  padding: 2px 5px;
  border-radius: 5px;
  background-color: #fafafa;
  border: 1px solid #d9d9d9;
  color: #505050;
}

button.reaction:hover {
  border-color: #a7a5a5;
}

button.reaction.liked {
  background: #d6b17e24;
  border-color: #d6b17e;
  color: #d6b17e;
  font-weight: 600;
}

button.btn.btn-search.custom {
  background-color: white;
  border-left: 1px solid #d9d9d9 !important;
  border-top: 1px solid #d9d9d9 !important;
  border-bottom: 1px solid #d9d9d9 !important;
  border-right: 1px solid #d9d9d9 !important;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}

button.btn.btn-search.custom:hover {
  box-shadow: none;
}

.btn-search.custom.clear:hover {
  color: #1d2a35;
}

.btn.btn-search.location {
  background-color: #d6b17e;
  border: 1px solid #d6b17e !important;
  color: white;
  height: 32px;
  max-height: 32px !important;
}

.btn.btn-search.location:hover {
  background-color: #d4ac74;
  border: 1px solid #d4ac74 !important;
}

div.bulletin-feed > .carousel .slider-wrapper.axis-horizontal .slider .slide {
  background-color: #f6f6f6 !important;
}

div.bulletin-feed > .carousel .control-next.control-arrow:before {
  border-left: 8px solid #1d2a35;
}

div.bulletin-feed > .carousel .control-prev.control-arrow:before {
  border-right: 8px solid #1d2a35;
}

div.bulletin-view.carousel.carousel-slider {
  height: 340px !important;
}

img.pswp__img {
  object-fit: contain !important;
}

button.pswp__button.pswp__button--share {
  display: none !important;
}

img.bulletin-attachment-img-preview {
  max-height: 650px;
  vertical-align: middle;
  object-fit: contain;
  margin: 0 auto;
}

img.bulletin-attachment-view {
  height: 340px;
  object-fit: contain;
}

div.bulletin-feed.carousel.carousel-slider {
  height: 140px !important;
}

img.bulletin-attachment-feed {
  height: 140px;
  object-fit: contain;
}

div.bulletin-view-mobile > div.carousel.carousel-slider {
  height: 280px !important;
}

img.bulletin-attachment-view-mobile {
  height: 280px;
  object-fit: contain;
}

img.bulletin-attachment-feed-mobile {
  width: 100%;
  object-fit: contain;
  height: 140px;
}

#floating-button {
  width: 55px;
  height: 55px;
  border-radius: 50%;
  background: #d6b27e;
  position: fixed;
  bottom: 30px;
  right: 30px;
  cursor: pointer;
  box-shadow: 0px 2px 5px #666;
}

.plus-sign {
  color: white;
  position: absolute;
  top: 0;
  display: block;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: center;
  padding: 0;
  margin: 0;
  line-height: 55px;
  font-size: 40px;
  font-weight: 300;
}

/*Text*/

h6 {
  display: inline-block;
  margin-top: -10px;
  margin-left: 20px;
  margin-bottom: -30px;
  color: #969a9a;
}

h3.post-status {
  font-size: 12px;
  display: inline-block;
  font-weight: 600;
  margin-left: 4px;
  margin-bottom: 0px;
  margin-top: 5px;
}

.post-main-content-container {
  display: inline-block;
  width: 68%;
  border-right: 1px solid #ededed;
  margin-right: 8px;
  padding-right: 8px;

  &.contracted {
    .container-fade {
      max-height: var(--contracted-post-max-height);
      overflow-y: hidden;
      mask-image: linear-gradient(to bottom, black 50%, transparent 100%);
    }
  }
}

div.post-main-content p > a:not(.bulletin-post-zero-link) {
  color: #03a9f4;
  margin: 0px;
  word-break: break-all;
}

div.post-main-content p > a:not(.bulletin-post-zero-link):hover {
  text-decoration: underline;
}

div.post-main-content-feed {
  display: inline-block;
}

div.post-details-content {
  width: 30%;
  display: inline-block;
  vertical-align: top;
}

.post-view-body-content {
  color: #505050;
  padding-top: 5px;

  a {
    color: #03a9f4;

    &:hover {
      text-decoration: underline;
    }
  }

  ol,
  ul {
    line-height: 1.5;
    margin: 0;
  }

  p {
    margin: 0;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}

a.bulletin-post-zero-link {
  background-color: #f6f6f6 !important;
  color: #1d2a35 !important;
  font-weight: 500 !important;
  padding: 3px !important;
}

a.bulletin-post-zero-link:hover {
  text-decoration: underline;
}

a.bulletin-post-zero-link > img {
  vertical-align: -4px;
  height: 17px;
  margin-right: 3px;
}

a.linkified {
  color: #03a9f4 !important;
}

a.linkified:hover {
  text-decoration: underline;
}

.ephox-summary-card {
  border: 1px solid #c5c5c5;
  padding: 5px;
}

.ephox-summary-card-thumbnail {
  /* max-width: 540px; */
  object-fit: contain;
  width: 100%;
  max-height: 150px;
}

.tox-edit-area {
  padding-bottom: 60px;
}

div.bulletin-activity.pull-right {
  /* display: inline-block; */
  /* margin-top: 45px; */
  /* left: 57%; */
  /* bottom: 6px;
	position: absolute; */
}

h3.post-details-header {
  font-size: 95%;
  display: inline-block;
  font-weight: 600;
  margin-bottom: 0px;
  margin-top: 0px;
  color: #505050;
  display: inline-block;
}

.post-details-content {
  font-size: 95%;
  display: inline-block;
  /* text-transform: capitalize; */
  margin-bottom: 0px;
  color: #505050;
}

.post-status {
  font-weight: 500;
}

.post-status.assigned {
  color: #1d2a35;
}

.post-status.closed {
  color: #6d981a !important;
}

.post-status.reopen {
  color: #f56946 !important;
}

.post-status-label.open {
  background-color: white !important;
  color: #1d2a35;
}

.post-status.updated,
.post-status.created,
.post-status.moved {
  color: #1d2a35;
}

.post-status.shared {
  color: #1d2a35 !important;
}

.post-comment {
  color: #505050;
  margin-bottom: 5px;
  font-size: 13px;

  a {
    color: #03a9f4 !important;

    &:hover {
      text-decoration: underline;
    }
  }
}

div.post-comment-edit-container {
  color: #505050;
  width: 49%;

  a {
    color: #03a9f4;
    word-break: break-all;
  }
}

p.post-comment-edit {
  color: #505050;
  margin-bottom: 5px;
  font-size: 12px;
  display: inline-block;
  /* width: 49%; */
  vertical-align: top;
}

p.post-comment-edit > span.bold {
  font-weight: 550;
}

p.bold {
  font-weight: 550;
}

p.post-comment-edit > span.underline {
  text-decoration: underline;
}

p.leading-indicator-settings {
  margin-left: 232px;
}

h3.article {
  color: #1d2a35 !important;
  font-size: 200%;
  font-weight: 700;
  margin-left: 18px;
  margin-bottom: 25px;
  display: inline-block;
}

h3.feed {
  font-size: 120%;
  font-weight: 600;
  margin-top: 5px;
  margin-left: 5px;
  margin-bottom: 15px;
  display: block;
  word-wrap: break-word;
  color: #1d2a35;
}

/* .navHeaderDropdown > .dropdown-menu{
	left: -215px !important
} */

h3.article:hover {
  color: #03a9f4 !important;
  cursor: pointer;
}

h3.bulletin-post-headers {
  color: #1d2a35;
  font-size: 100%;
  margin-left: 20px;
  margin-bottom: 10px;
  display: inline-block;
}

h3.bulletin-aside-filter {
  color: #505050;
  margin-bottom: 3px;
  margin-top: 0px;
  font-size: 100%;
}

h3.titles {
  color: #1d2a35;
  font-size: 130%;
  margin-left: 20px;
  margin-bottom: 10px;
  display: inline-block;
}

h3.titles.account {
  color: #1d2a35;
  font-size: 110%;
  margin-left: 0px;
  margin-bottom: 0px;
  display: inline-block;
}

h3.titles.account.center-panel {
  margin-bottom: 4px;
}

h3.author {
  font-size: 110%;
  margin-left: 10px;
  margin-top: 0px;
  display: inline-block;
  margin-bottom: 0px;
  margin-top: 3px;
}

small.author {
  font-weight: 600;
  margin-left: 10px;
}

.error {
  color: #ce1242;
}

h3.date {
  color: #8f9ea6;
  font-weight: normal;
  font-style: normal;
  font-size: 100%;
  margin-left: 0px;
  margin-top: 7px;
  display: inline-block;
  float: right;
}

h3.date-article-view {
  color: #8f9ea6;
  font-weight: normal;
  font-style: normal;
  font-size: 100%;
  margin-left: 0px;
  margin-top: 8px;
  margin-bottom: 13px;
  display: inline-block;
  float: right;
  margin-right: 24px;
}

h3.date.bulletin {
  margin-top: 4px !important;
}

span.label.label-primary.tags.bulletin {
  margin-left: 15px !important;
  display: block;
}

span.user-counter:hover {
  cursor: pointer;
  text-decoration: underline;
}

span.underline {
  text-decoration: underline;
}

.strike-through {
  text-decoration: line-through;
}

.underline-hover:hover {
  text-decoration: underline;
}

h3.contribute {
  color: #03a9f4;
  margin-top: 6px;
  margin-left: 11px;
  font-size: 110%;
  display: inline-block;
}

h3.contribute:hover {
  color: #1d2a35;
  cursor: pointer;
}

h1.page-header.text-overflow {
  color: #1d2a35 !important;
  font-weight: 600 !important;
}

.center-panel {
  margin: 0 auto;
  display: block !important;
  text-align: center;
  margin-left: 0px;
}

.center-panel.input {
  max-width: 220px;
}

.fw-600 {
  font-weight: 600;
}

/*Images*/

.img-circle.article,
.default-avatar-circle.article {
  height: 26px;
  width: 26px;
}

.default-avatar-circle.article > .initials {
  line-height: 26px;
  font-size: 9.5px;
}

.img-circle.article.small,
.default-avatar-circle.article.small {
  display: inline-block;
  margin-right: 5px;
  height: 20px;
  width: 20px;
}

.default-avatar-circle.article.small > .initials {
  line-height: 20px;
  font-size: 7px;
}

.img-circle.bulletin,
.default-avatar-circle.bulletin {
  height: 26px;
  width: 26px;
}

.default-avatar-circle.bulletin > .initials {
  line-height: 26px;
}

.img-circle.new-post,
.default-avatar-circle.new-post {
  height: 35px;
  width: 35px;
}

.default-avatar-circle.new-post > .initials {
  line-height: 35px;
}

.img-circle.sidebar,
.default-avatar-circle.sidebar {
  height: 51px;
  width: 51px;
}

.default-avatar-circle.sidebar > .initials {
  line-height: 51px;
}

.img-circle.new-comment,
.default-avatar-circle.new-comment {
  height: 32px;
  width: 32px;
}

.default-avatar-circle.new-comment > .initials {
  line-height: 32px;
}

img.img-circle.dropdown,
.default-avatar-circle.dropdown {
  margin-right: 5px;
  height: 20px;
  width: 20px;
}

.default-avatar-circle.dropdown > .initials {
  line-height: 20px;
  font-size: 9px;
  vertical-align: middle;
}

.img-circle.user-card,
.default-avatar-circle.user-card {
  height: 50px;
  width: 50px;
}

.default-avatar-circle.user-card > .initials {
  line-height: 50px;
}

.img-circle.views {
  height: 65px;
  width: 65px;
}

.default-avatar-circle.views {
  height: 65px;
  width: 65px;
}

.default-avatar-circle.views > .initials {
  line-height: 65px;
}

.img-circle.top-posters,
.default-avatar-circle.top-posters {
  height: 40px;
  width: 40px;
}

.default-avatar-circle.top-posters > .initials {
  line-height: 40px;
}

img.img-circle.notification,
.default-avatar-circle.notification {
  height: 24px;
  width: 24px;
}

.default-avatar-circle.notification > .initials {
  line-height: 24px;
  font-size: 9px;
  font-weight: bold;
}

.default-avatar-circle.account {
  height: 100px;
  width: 100px;
  margin: 20px auto;
}

.default-avatar-circle.account > .initials {
  line-height: 100px;
  font-size: 30px;
}

.img-circle.kiosk,
.default-avatar-circle.kiosk {
  height: 140px;
  width: 140px;
  margin: 0 auto;
}

.default-avatar-circle.kiosk > .initials {
  line-height: 140px;
  font-size: 35px;
}

img.img-circle {
  object-fit: contain !important;
}

img.img-circle.img-sm.company {
  height: 30px;
  width: unset;
  border-radius: 0px !important;
}

img.img-responsive.article {
  margin-top: 9px;
  margin-bottom: 25px;
  border-radius: 2px;
}

div.profile-img > a:hover {
  text-decoration: none;
}

.default-avatar-circle {
  text-align: center;
  vertical-align: middle;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
}

.default-avatar-circle:hover {
  text-decoration: none !important;
}

.initials {
  position: relative;
  font-size: 100%;
  color: #fff;
  text-transform: uppercase;
  text-align: center;
  vertical-align: middle;
  margin-bottom: 0px;
}

p.initials:hover {
  text-decoration: none !important;
}

div.thumbnail {
  margin-bottom: unset;
}

.brand-logo {
  display: inline-block;
  height: 20px;
  margin: 18px 0px 0px 15px;
  padding-right: 10px;
}

.brand-logo-sidebar {
  display: inline-block;
  height: 3.5rem;
  margin-bottom: 0.5rem;
}

div.thumbnail.noborder {
  border: none;
  padding: 5px !important;
}

div.mce-tabs > div.mce-tab:nth-child(3) {
  visibility: hidden !important;
}

div.library-list,
div.library-topics {
  -webkit-overflow-scrolling: touch !important;
}

div.library-panel-height {
  height: 85vh;
  overflow: hidden;
}

div.library-list {
  overflow-y: scroll !important;
  /* max-height: 600px; */
  max-height: 75vh;
}

div.library-topics {
  overflow-y: scroll !important;
  /* max-height: 600px; */
  max-height: 68vh;
}

/*Table*/

tbody > tr.tr-hover:hover {
  background-color: rgba(0, 0, 0, 0.015);
}

.table-borderless th,
.table-borderless td {
  border-left: none !important;
  border-right: none !important;
}

.react-bs-table-container.directory-table > .react-bs-table {
  /* max-height: 600px !important; */
  /* overflow-y: scroll; */
}

.react-bs-table-container.directory-table
  > .react-bs-table
  > .react-bs-container-body {
  /* max-height: 550px !important */
}

.react-bs-table-container.directory-table,
.react-bs-table-container.access-teams-table {
  -ms-overflow-style: none;
  -webkit-overflow-scrolling: touch;
}

.react-bs-table-container.directory-table
  > .react-bs-table.react-bs-table-bordered,
.react-bs-table-container.access-teams-table
  > .react-bs-table.react-bs-table-bordered,
.react-bs-table-container.directory-table > .react-bs-table {
  background-color: white;
}

.react-bs-table-container.directory-table > .react-bs-table {
  border: 1px solid #ddd;
  border-radius: 5px;
  outline: none !important;
}

.react-bs-table-container.directory-table
  > .react-bs-table
  > div.react-bs-container-body
  > table
  > tbody
  > tr
  > td {
  outline: none !important;
}

.react-bs-table-container.directory-table > .react-bs-table-tool-bar,
.react-bs-table-container.access-teams-table > .react-bs-table-tool-bar {
  display: none;
}

ul.react-bootstrap-table-page-btns-ul.pagination {
  margin-bottom: 5px;
  background-color: white;
}

ul.react-bootstrap-table-page-btns-ul.pagination
  > li.active.page-item
  > a.page-link {
  background-color: #03a9f4;
  border-color: #03a9f4;
}

div.ReactVirtualized__Grid.ReactVirtualized__Table__Grid {
  outline: none;
}

div.ReactVirtualized__Table__headerRow {
  border-bottom: 2px solid #ededed;
}

div.ReactVirtualized__Table__row {
  border-bottom: 1px solid #f6f6f6;
}

span.ReactVirtualized__Table__headerTruncatedText {
  vertical-align: middle;
  text-transform: capitalize;
  font-weight: 600;
}

.ReactVirtualized__Table .ReactVirtualized__Table__headerTruncatedText {
  display: inline-block;
  max-width: 100%;
  white-space: normal;
  overflow: visible;
}

div.fixed-table-body {
  height: unset;
}

.center-label {
  text-align: center !important;
}

td {
  font-weight: 500;
  font-size: 1.3rem;
  vertical-align: middle !important;
  color: black;
  text-align: unset;
}

.tr-current-session {
  background-color: #d8f3d2;
}

a.btn-link:hover {
  cursor: pointer;
}

a.btn-link {
  font-weight: 500;
  font-size: 1.3rem;
  vertical-align: middle !important;
  color: #03a9f4;
}

a.btn-link.sign-up {
  vertical-align: unset !important;
  text-decoration: underline;
}

.disabled-link {
  pointer-events: none;
  color: rgb(151, 159, 163);
}

div.th-inner.sortable.both {
  font-weight: 700;
  font-size: 1.3rem;
  vertical-align: middle !important;
  color: #1d2a35;
  text-align: left;
}

.pagination > .active > a {
  background-color: #03a9f4;
  border-color: #03a9f4;
}

.pagination > li a:hover {
  border-color: #03a9f4;
  color: #03a9f4;
  box-shadow: inset 0 0 1px #03a9f4, 0 3px 15px rgba(0, 0, 0, 0.25);
}

div.columns.columns-right.btn-group.pull-right {
  float: left;
}

/* span.Select-value-icon {
	color: white;
	background-color: #1D2A35;
	border-right: 0px !important;
	padding-top: 5px;
} */

/* .Select--multi .Select-value-icon:hover {
	color: white;
	background-color: #1D2A35;
} */

div.Select.Select--multi {
  width: 100%;
}

/* span.Select-value-label {
	color: white;
	background-color: #1D2A35;
}

div.Select-value {
	border: 0px !important;
	margin-top: 8px !important;
}  */

.Select-option.is-focused {
  background-color: #03a9f4 !important;
  color: white !important;
}

div.buttons-right {
  display: inline-block;
  float: right;
}

li.active-item-sidebar {
  background-color: #3c576c;
  color: #fff;
  font-weight: 600;
  padding-left: 13px;
  padding-right: 13px;
  border-radius: 3px;
  margin: 0 5px;
}

a.active-sidebar-header {
  background-color: #1d2a35;
  margin: 0px 5px;
  border-radius: 3px;
  padding: 10px !important;
  color: white !important;
}

a.active-sidebar-header:hover {
  background-color: #1d2a35 !important;
  color: white !important;
}

li > a.active-link {
  color: #1d2a35;
  font-weight: 600;
  /* padding: 10px !important;
	border-radius: 3px;
	margin: 0 5px; */
}

li > a.active-link > p.menu-title {
  color: #d6b27e;
}

li > a.active-sidebar-item {
  background-color: #1d2a35;
  font-weight: 600;
  padding-left: 10px !important;
  border-radius: 3px;
  margin: 0 5px;
}

li > a.active-sidebar-item:hover {
  background-color: #1d2a35 !important;
}

li > a > p.menu-title,
li > a > span.menu-title,
li > a > span.fa,
li > a > span.fas {
  color: #505050;
}

li > a.active-sidebar-item > p.menu-title,
li > a.active-sidebar-item > span.menu-title,
li > a.active-sidebar-item > span.fa,
li > a.active-sidebar-item > span.fas {
  color: white;
}

span.Select-clear-zone {
  display: none;
}

div.ant-select-selection-selected-value
  > span.badge.badge-icon.badge-fw.pull-left {
  margin-top: 11px !important;
}

.views {
  float: right;
}

.views.article {
  float: none;
}

.anticon.anticon-copy:hover {
  cursor: pointer;
}

a.views {
  /* margin-top: 7px; */
  color: #1d2a35;
  font-weight: 500;
  display: inline-block;
}

a.views:hover {
  color: #03a9f4;
  cursor: pointer;
}

.label.label-primary.tags {
  font-size: 85%;
  border-radius: 20px;
  background-color: #e3e8ee;
  color: #1d2a35;
  float: left;
  display: inline-block;
  margin: 10px 10px 10px 0;
  padding: 9px 13px 9px 13px;
}

button.btn.btn-info.tags {
  font-size: 100%;
  border-radius: 9px;
  border-color: rgb(217, 223, 228) !important;
  background-color: #ffffff;
  color: #a2a7ac;
  float: left;
  display: inline-block;
  padding: 5px 11px 5px 11px;
  margin: 5px 10px 5px 0px;
}

button.btn.btn-info.tags:hover {
  border-color: #e3e8ee !important;
  background-color: #e3e8ee !important;
  color: #1d2a35 !important;
}

button.btn.btn-info.tags.active {
  border-color: #e3e8ee !important;
  background-color: #e3e8ee !important;
  color: #1d2a35 !important;
  box-shadow: unset;
}

button.btn.btn-info.risk > h3.article.filters.bulletin {
  margin-left: 0px;
}

button.btn.btn-info.risk {
  text-align: center;
  width: 75px;
  font-size: 100%;
  border-radius: 3px;
  border-width: 1.5px;
  border-color: transparent !important;
  background-color: #ffffff;
  color: #1d2a35 !important;
  display: block;
  padding: 2px;
  margin: 5px 5px 5px 0px;
}

button.btn.btn-info.risk:hover {
  border-color: #1d2a35 !important;
  color: #1d2a35 !important;
}

button.btn.btn-info.risk.Low {
  background-color: #a9d15c !important;
}

button.btn.btn-info.risk.Low.active {
  border-color: #1d2a35 !important;
  box-shadow: unset;
}

button.btn.btn-info.risk.Medium {
  background-color: #fee379 !important;
}

button.btn.btn-info.risk.Medium.active {
  border-color: #1d2a35 !important;
  box-shadow: unset;
}

button.btn.btn-info.risk.High {
  background-color: #f58768 !important;
}

button.btn.btn-info.risk.High.active {
  border-color: #1d2a35 !important;
  box-shadow: unset;
}

button.btn.btn-info.risk.NA {
  background-color: #e3e8ee !important;
}

button.btn.btn-info.risk.NA.active {
  border-color: #1d2a35 !important;
  box-shadow: unset;
}

.btn.btn-primary.post-btn.cancel {
  background-color: #1d2a35 !important;
  border-color: #1d2a35 !important;
  margin: 15px 8px 0 0 !important;
}

.btn.btn-primary.post-btn.cancel:hover {
  background-color: #1f7da4 !important;
  border-color: #1f7da4 !important;
}

p.text-muted {
  color: #7e7e7e;
}

button.text-muted.teams {
  left: -10px;
}

.searchbox > .input-group input:focus ~ .input-group-btn:before {
  right: 0px;
}

.searchbox > .input-group input.input-group-btn:before {
  right: 0px;
}

.searchbox .custom-search-form .input-group-btn:before {
  background-color: #1d2a35;
  height: 0px;
}

.searchbox > .input-group.custom-search-form input {
  border-bottom: 0px;
}

a.list-group-item.main-team {
  background-color: #f7f7f7 !important;
  margin-top: 7px;
  border: solid #e3e8ee !important;
  border-style: solid !important;
  border-width: 0px !important;
  border-radius: 4px !important;
  margin-bottom: 10px;
}

li.list-group-item.notification:hover {
  background-color: #fbfbfb !important;
}

h5.mar-no.text-main {
  margin-left: 5px !important;
  margin-top: 3px;
}

p.mar-no.text-main.selected:hover {
  text-decoration: underline;
}

.btn.btn-primary.post-btn.add {
  background-color: #1d2a35;
  border-color: #1d2a35 !important;
  margin-top: 0px !important;
  margin-right: 6px !important;
  margin-bottom: 15px;
  float: right !important;
}

.btn.btn-primary.post-btn.add:hover {
  background-color: #1f7da4;
  border-color: #1f7da4;
}

.btn.btn-primary.post-btn.team {
  margin-top: 24px;
  margin-right: 5px !important;
}

div.searchbox.teams {
  display: inline-block;
  margin: auto;
  width: 67%;
  background-color: #f3f3f3;
  margin-top: 25px;
  margin-bottom: 2px;
  border-radius: 4px;
}

div.searchbox.teams.team {
  margin: 0px auto;
  margin-top: 20px;
  margin-bottom: 10px;
  float: unset !important;
  text-align: center;
  max-width: 600px;
  width: 80%;
  display: block;
}

.media-left.pull-right.pos-rel.users {
  display: inline-block;
}

.more {
  background-color: #f6f6f6;
  border-radius: 4px;
  padding-left: 4px;
  padding-right: 4px;
  border: 1px solid #d9d9d9;
}

.media-right.more {
  padding-left: 4px;
  padding-right: 4px;
  border-radius: 4px;
  margin-top: 5px;
  margin-left: 8px;
  background-color: #f6f6f6;
}

.more:hover,
.media-right.more:hover {
  background-color: #eeeeee;
}

.media-right.options {
  padding-right: 6px;
}

.media-right.options > button,
.media-right.options > a {
  padding-left: 4px;
  padding-right: 4px;
  padding-bottom: 3px;
  border-radius: 4px;
  background-color: #f6f6f6;
}

.media-right.options > button:hover,
.media-right.options > a:hover {
  background-color: #eeeeee;
}

.btn-link.resend {
  margin-top: 7px !important;
}

.list-group.bg-trans.teams {
  /* height: 460px;  */
  overflow-y: auto;
  border: 1px !important;
  border-color: #f3f3f3 !important;
  border-style: solid !important;
  border-radius: 4px;
  padding: 0px 15px 0px 15px;
  box-shadow: inset 0 0 4px #e3e8ee;
}

.bottom-border-panel {
  border-bottom: 0px !important;
  border-bottom-color: #f3f3f3 !important;
  border-bottom-style: solid !important;
}

.panel-footer.team {
  background-color: white;
  border-top: 0px !important;
  border-top-color: white !important;
  border-top-style: solid !important;
}

h3.team {
  color: #1d2a35 !important;
  font-size: 150%;
  font-weight: 700;
  margin-left: 4px;
  display: inline-block;
}

h3.subtitle {
  color: #7c7c7c;
  font-size: 110%;
  margin-left: 4px;
  margin-bottom: 0px;
  margin-top: 0px;
  display: inline-block;
}

.team-spacing {
  margin-top: 15px;
}

p.team-select {
  float: left;
  margin-bottom: 0px;
}

.btn.btn-primary.post-btn.team-top {
  margin: 15px 0px 0 0 !important;
}

img.img-responsive.onboarding {
  margin-top: 30px;
  width: 70px;
}

img.img-responsive.onboarding.smaller {
  width: 50px !important;
}

h3.team.onboarding {
  text-align: center !important;
  margin-left: 0px !important;
}

a.thumbnail.onboarding {
  height: 200px;
  margin-bottom: 10px;
}

p.text-main.text-bold.mar-no.onboarding {
  font-size: 140%;
}

.btn.btn-primary.post-btn.onboarding {
  float: left !important;
  margin-left: 5px !important;
}

.btn.btn-primary.post-btn.onboarding.off {
  border-color: grey;
  background-color: grey;
}

p.mar-no.text-main.inactive {
  opacity: 0.5 !important;
}

small.text-muteds.inactive {
  opacity: 0.5 !important;
}

.btn.btn-default.teams {
  margin-top: -18px !important;
}

img.img-circle.img-sm.inactive {
  opacity: 0.5 !important;
}

.btn.btn-default.teams:hover {
  box-shadow: none !important;
  background-color: #03a9f4 !important;
  border-color: #03a9f4 !important;
  color: white !important;
}

p.mar-no.text-main {
  font-weight: 500;
  font-size: 115%;
  margin-top: 5px !important;
  margin-left: 5px !important;
}

p.mar-no.text-main:hover {
  text-decoration: underline;
  cursor: pointer;
}

p.mar-no.text-main.header {
  color: #1d2a35 !important;
  font-weight: 700;
  font-size: 125%;
  margin-left: 15px !important;
  margin-top: 10px !important;
  margin-bottom: 10px !important;
  cursor: unset !important;
  display: inline-block;
}

small.text-muteds {
  font-weight: 600;
  margin-left: 5px !important;
}

a.list-group-item.team:hover {
  background-color: #f6f6f6 !important;
}

div.list-group-item.team:hover {
  background-color: #f6f6f6 !important;
}

#aside > div > div.list-group.bg-trans > li.list-group-item:hover {
  background-color: #fbfbfb !important;
}

a.thumbnail.onboarding {
  height: 200px;
  margin-bottom: 10px;
}

img.media-object.img-sm.onboarding {
  margin-right: 15px;
  padding: 10px;
  margin-top: -4px;
  display: inline-block;
}

div.well.well-sm.onboarding {
  display: inline-block;
  text-align: left;
  margin-bottom: unset;
  background-color: #ffffff;
  /* border-color: rgba(93, 135, 170, 0.21); */
  border-radius: 2px;
  margin-top: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
  color: #1d2a35;
  width: 80%;
}

div.well.well-sm.onboarding.disabled {
  text-align: left;
  background-color: #e9eeef;
  border-color: rgba(0, 0, 0, 0.07);
  border-radius: 2px;
  padding-top: 10px;
  padding-bottom: 10px;
  color: #7a878e;
  display: block;
  margin: 0 auto;
  width: 99%;
  margin-top: 10px;
}

p.account-label {
  text-align: center;
}

h4.text-lg.text-overflow.mar-no.account {
  display: inline-block;
  font-size: 170%;
  font-weight: 500 !important;
  margin-left: 20px !important;
  margin-bottom: 10px;
  margin-top: 7px !important;
}

small.invited {
  color: #c3c3c3;
  font-weight: 600;
  font-size: 87%;
  margin-right: 15px;
  margin-bottom: 0px;
  margin-top: -9px;
  display: inline-block;
}

p.admin-text {
  color: #03a9f4;
  font-weight: 600;
  font-size: 87%;
  margin-left: 5px;
  margin-bottom: 0px;
  margin-top: 5px;
  display: inline-block;
}

p.menu-selection {
  display: inline-block;
}

.form-control.custom-placeholder.location {
  -webkit-appearance: none;
}

.new-location > .form-row > div.form-group.col-md-12 {
  padding: 0px;
}

.new-location > .form-row > div.form-group.col-md-6 {
  padding-left: 0px;
}

.new-location > .form-row > div.form-group.col-md-5 {
  padding-left: 0px;
}

.new-location > .form-row > div.form-group.col-md-3 {
  padding-left: 0px;
}

.new-location > .form-row > div.form-group.col-md-2 {
  padding-left: 0px;
}

.location-select {
  float: left !important;
  margin-top: 0px;
  margin-left: 5px;
}

div.panel-heading.account {
  margin-left: 0px;
  padding: 20px 0px 0px;
  min-height: 10px !important;
  margin-bottom: 6px;
  margin-top: 0px;
}

div.panel-body.account {
  height: unset !important;
  padding: unset;
  padding-left: 15px;
  padding-right: 15px;
  padding-bottom: 10px;
  padding-top: 5px;
}

div.panel-heading.team {
  margin-left: -2px;
  padding-top: 5px;
  padding-bottom: 7px !important;
  height: unset !important;
  padding: unset;
}

div.panel-body.team {
  height: unset !important;
  padding: unset;
  padding-left: 15px;
  padding-right: 15px;
  padding-bottom: 10px;
}

div.panel-body.account.attachment {
  max-width: 230px;
}

label.btn.btn-primary.post-btn.onboarding.fileinput-button.dz-clickable {
  margin-left: 40px;
}

h3.article.filters {
  font-size: 100%;
  font-weight: 600;
  margin-top: 3px;
  margin-left: 0px;
  margin-bottom: 5px;
  display: block;
  word-wrap: break-word;
}

h3.article.filters.active {
  color: white !important;
}

h3.article.filters.bulletin:hover {
  color: #1d2a35 !important;
}

.mainnav-profile .dropdown-caret,
.mainnav-profile .mnp-desc {
  opacity: 1;
  color: #505050;
}

div#profile-nav > a.list-group-item,
div#profile-nav > button.list-group-item {
  color: #505050;
}

div#profile-nav > a.list-group-item:not(.active-sidebar-item):hover,
div#profile-nav > button.list-group-item:not(.active-sidebar-item):hover {
  background-color: #fbfbfb !important;
}

div#profile-nav > a.list-group-item.active {
  color: #fff !important;
}

div#profile-nav > a.list-group-item.active:hover {
  background-color: #1d2a35;
}

#profile-nav > a.active-sidebar-item {
  background-color: #1d2a35 !important;
  font-weight: 600;
  padding: 10px !important;
  border-radius: 3px;
  margin: 0 5px;
  color: white !important;
}

#profile-nav > a.active-sidebar-item:hover {
  background-color: #1d2a35 !important;
  color: white !important;
}

.list-group-item.active {
  /* color: #45BCDE !important; */
  background-color: #1d2a35 !important;
}

button.list-group-item.library-tag.active {
  background-color: #1d2a35 !important;
  padding-left: 13px;
  padding-right: 13px;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
  /* margin: 0px 5px; */
}

button.list-group-item.library-tag.active {
  background-color: #1d2a35 !important;
  color: white !important;
}

button.list-group-item.library-tag.active:hover {
  background-color: #1d2a35 !important;
  color: white !important;
  margin: 0px !important;
}

/* div#profile-nav > a.list-group-item {
	padding: 12px 15px;
} */

a.list-group-item.active {
  background-color: #1d2a35 !important;
  color: white !important;
}

a.list-group-item.active:hover {
  background-color: #1d2a35 !important;
  color: white !important;
}

p.pad-hor.text-semibold.text-main.filter {
  margin-left: 5px;
}

img.img-sm.team {
  border-radius: 4px;
}

div.input-group.search-bar {
  padding: 15px;
}

button.btn-search {
  border-width: 2px;
}

.nano > .nano-pane > .nano-slider {
  background-color: #1d2a35;
}

img.img-sm.team.onboarding {
  float: left;
  border-radius: 10px;
  padding: 6px;
  margin-top: 2px;
  margin-left: -15px;
  margin-right: 5px;
}

p.account-description.pre {
  display: block;
  font-size: 140%;
  font-weight: 500;
  text-align: center;
  margin-top: -5px;
}

p.account-label.pre {
  display: block;
  color: #a8a8a8;
  font-size: 100%;
  font-weight: 500;
  margin-top: 30px;
}

p.account-description {
  display: block;
  font-size: 125%;
  font-weight: 500;
  text-align: center;
  margin-top: -5px;
}

p.account-label {
  display: block;
  color: #3c4045;
  font-size: 100%;
  font-weight: 600;
  margin-top: 30px;
  font-weight: 700;
  text-transform: uppercase;
}

.list-group-item.team {
  margin: 0 auto;
  text-align: left;
}

.list-group-item.team.account {
  margin-left: 150px;
  margin-right: 150px;
}

div.bottom-border-panel.account {
  width: 55%;
  float: none;
  margin: 0 auto;
  height: unset !important;
}

.list-group.bg-trans.teams.account {
  /* height: 280px; */
}

.list-group.bg-trans.teams.account.no-scroll {
  height: auto;
  -ms-overflow-style: none;
}

h4.account-title {
  text-align: center;
  display: block;
  font-size: 180%;
  font-weight: 600 !important;
  margin-top: 25px !important;
  color: #1d2a35;
}

img.img-big.img-circle.account {
  width: 100px;
  height: 100px;
  display: block;
  float: none;
  margin: 20px auto;
  box-shadow: 0 0 0 3px rgba(29, 42, 53, 0) !important;
}

.incident-collapse .ant-collapse-header {
  font-weight: 600;
  color: #1d2a35 !important;
  text-decoration: underline;
}

.form-collapse .ant-collapse-header {
  font-weight: 600;
  color: #1d2a35 !important;
  text-decoration: underline;
}

.form-collapse .ant-collapse-extra {
  font-weight: 500;
  font-size: 13px;
}

.box-block.collapsed.pointer-hover:hover {
  cursor: pointer;
}

h3.directory {
  color: #1d2a35 !important;
  font-size: 150%;
  font-weight: 700;
  margin-left: 23px;
  margin-bottom: 5px;
  display: inline-block;
}

h3.directory.add {
  margin-left: 5px;
}

div.views-modal {
  overflow: scroll;
  margin-top: 8px;
  max-height: 310px;
}

div.media-left.profile-img {
  padding-top: 0px !important;
}

.required:after {
  content: "*";
}

div.badge-block-panel {
  display: block;
  width: 100%;
}

#container .badge {
  font-size: 75%;
}

div.ant-popover-title {
  padding: 8px;
}

div.ant-popover {
  z-index: 11105 !important;
}

ul.ant-menu.org-switch-menu {
  width: 240px;
  list-style: none;
  padding: 0px;
  margin-bottom: 0px;
  outline: none !important;
  border: none;
  border-inline-end: none !important;
}

ul.ant-menu.org-switch-menu > li > p {
  margin-bottom: 0px;
  padding-top: 0px;
  display: inline-block;
  height: 28px;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 215px;
  white-space: nowrap;
}

ul.ant-menu.org-switch-menu > li {
  padding: 0px;
  margin: 0px !important;
}

ul.ant-menu.org-switch-menu > li:hover {
  background-color: #e7f7ff;
  cursor: pointer;
}

div.ant-message {
  z-index: 1110;
}

.ant-message-notice {
  float: right !important;
  text-align: left !important;
  max-width: 250px;
  margin-top: 36px !important;
}

.ant-message-notice-content {
  /* background-color: #E75C50 !important;  */
  padding: 0px !important;
}

.anticon.anticon-cross.remove {
  color: white;
  top: 2px;
  right: 2px;
  background-color: #1d2a35;
  border-radius: 3px;
}

.anticon.anticon-cross.remove:hover {
  color: white;
}

i.anticon.anticon-cross-circle {
  color: white !important;
  display: none !important;
}

i.anticon.anticon-check-circle {
  color: white !important;
  display: none !important;
}

.ant-message-custom-content {
  padding: 10px 16px;
  border-radius: 4px;
}

.ant-message-custom-content.ant-message-error {
  background-color: #e75c50 !important;
}

.ant-message-custom-content.ant-message-success {
  background-color: #8bbd57 !important;
}

.ant-message-custom-content > span {
  color: white !important;
}

.ant-dropdown-menu-item-divider {
  margin: 5px 0px;
}

.notification-badge.ant-badge > sup {
  background-color: #8493a0;
  height: 16px !important;
  min-width: 16px !important;
  line-height: 16px;
  border-radius: 8px !important;
  font-size: 10px !important;
  padding: 0 4px !important;
  font-weight: 700;
  top: 4px;
}

.ant-badge-multiple-words {
  padding: 0 4px !important;
}

.ant-badge-status-processing:after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border: 1px solid #03a9f4 !important;
  content: "";
  -webkit-animation: antStatusProcessing2 1.7s infinite ease-in-out !important;
  animation: antStatusProcessing2 1.7s infinite ease-in-out !important;
}

@-webkit-keyframes antStatusProcessing2 {
  0% {
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
    opacity: 0.9;
  }
  100% {
    -webkit-transform: scale(2.4);
    transform: scale(2.4);
    opacity: 0;
  }
}

@keyframes antStatusProcessing2 {
  0% {
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
    opacity: 0.9;
  }
  100% {
    -webkit-transform: scale(2.4);
    transform: scale(2.4);
    opacity: 0;
  }
}

p.role-text {
  margin: 0px;
  font-size: 13px;
}

p.badge {
  float: left;
  width: 45px;
  border-radius: 3px;
  height: 20px;
  background-color: #64c3fc;
  font-size: 11px !important;
  margin-right: 5px;
  margin-left: 2px;
  margin-top: 4px;
  margin-bottom: 0px;
  padding: 5px 3px 5px 3px;
}

span.badge.blue {
  background-color: #64c3fc;
  font-size: 9px !important;
  margin-top: 1px;
  margin-left: 4px;
  vertical-align: top;
  font-size: 75% !important;
}

span.badge.grey {
  background-color: #03a9f4;
  font-size: 9px !important;
  margin-top: 0px;
  margin-left: 4px;
  vertical-align: text-top;
  padding-top: 4px;
  font-size: 75% !important;
}

p.badge.posts_total {
  /* width: auto; */
  background-color: transparent;
  color: #505050 !important;
  margin-left: -70px;
  margin-top: 7px;
  position: absolute;
  float: none;
  font-size: 83% !important;
}

p.badge.schedules_total {
  /* width: auto; */
  background-color: transparent;
  color: #505050 !important;
  margin-left: -105px;
  margin-top: 7px;
  position: absolute;
  float: none;
  font-size: 83% !important;
}

p.badge.members_total {
  /* width: auto; */
  background-color: transparent;
  color: #505050 !important;
  margin-left: -85px;
  margin-top: 8px;
  position: absolute;
  float: none;
  font-size: 83% !important;
}

p.badge.members_total.hundred {
  margin-left: -95px;
}

p.badge.members_total.thousand {
  margin-left: -105px;
}

p.badge.pending {
  background-color: #fdb22b;
  width: 54px;
}

p.chart-subtitle {
  display: inline-block;
  float: right;
  text-transform: uppercase;
  line-height: 50px;
  padding: 0 20px 0 10px;
  margin-bottom: 0px;
}

.btn.btn-primary.post-btn.add-user {
  margin-right: 8px !important;
  margin-top: 20px !important;
  display: inline-block !important;
  border-radius: 50px;
}

.btn.btn-primary.post-btn.team.edit {
  margin-top: 20px;
  margin-right: 30px !important;
  border-color: white !important;
  background-color: white !important;
  color: #1d2a35 !important;
  padding: 4px 6px 4px 6px;
}

.btn.btn-primary.post-btn.team.edit:hover {
  border-color: rgb(241, 238, 238) !important;
  background-color: rgb(241, 238, 238) !important;
  color: #1d2a35 !important;
}

.btn.btn-primary.post-btn.team.edit:focus {
  border-color: white !important;
  background-color: unset !important;
  color: #03a9f4;
  text-decoration: underline;
}

.btn.btn-primary.discard-article {
  background-color: white;
  border-color: #1d2a35 !important;
  color: #1b2a35;
}

.btn.btn-primary.discard-article:hover {
  background-color: #33495c !important;
  border-color: #33495c !important;
}

div.panel.clear {
  background-color: transparent !important;
}

div.react-bs-table-container {
  overflow-x: unset !important;
}

.btn.btn-primary.post-btn.panel-user-add {
  margin-top: 14px;
  margin-right: 5px !important;
}

h3.titles.onboarding {
  margin-left: 1px;
  margin-bottom: 0px;
}

h3.titles.onboarding.invite-link {
  display: inline-block;
  margin-bottom: 10px;
  margin-top: 10px;
}

input.form-control.onboarding {
  margin: 0 auto;
  width: 99%;
}

.custom-placeholder {
  border: solid 1px #d9d9d9;
}

.form-control.form-control.custom-placeholder.edit-comment {
  width: 100%;
  border-radius: 4px;
  /* border: solid 1px #e2e2e2; */
  /* border: solid 1px #d9d9d9; */
  height: 70px !important;
  resize: none;
}

span.ant-switch {
  margin-top: 16px;
  float: left;
  background-color: rgba(120, 120, 120, 0.25);
}

p.invite-onboarding {
  text-align: left;
  float: left;
  display: inline-block;
  color: #a8a8a8;
  font-size: 100%;
  font-weight: 600;
  margin: 20px 0px 0px 10px;
}

div.well.well-sm.onboarding {
  display: block;
  margin: 0 auto;
  width: 99%;
  background-color: white;
  margin-top: 10px;
}

.ant-switch-checked {
  background-color: #03a9f4 !important;
}

.thumbnail.onboarding.active {
  background-color: #f6f6f6;
  border-color: #03a9f4;
  border-width: 1px;
}

.thumbnail.onboarding:hover {
  border-color: #03a9f4;
  border-width: 1px;
}

button.small.invited.add-admin {
  border-color: white;
  color: #1d2a35;
  text-decoration: underline;
  font-weight: 600;
  font-size: 87%;
  margin-right: 15px;
  margin-bottom: 0px;
  margin-top: -9px;
  display: inline-block;

  background: none !important;
  border: none;
  padding: 0 !important;
  cursor: pointer;
  outline: inherit !important;
}

button.small.invited.add-admin::selection {
  background-color: white;
}

button.small.invited.add-admin:hover {
  color: #03a9f4;
}

button.list-group-item.team.admin:hover {
  background-color: #eeeeee !important;
}

p.item.lg.analytics-tag {
  color: #6e7a81;
  display: inline-block;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 25ch;
  white-space: nowrap;
}

div.analytics-tag-name {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

li.list-header.post-analytics {
  padding-top: 5px !important;
  padding-bottom: 5px !important;
}

li.list-header.post-analytics > a > p {
  margin-bottom: 0px;
  font-weight: 500;
  display: inline-block;
}

li.list-header.post-analytics:hover {
  cursor: pointer;
  background-color: #fbfbfb;
}

/* p.analytics-tag {
	width: 270px
} */
tr.analytics-indicator {
  height: 31px;
}

tr.analytics-indicator:hover {
  cursor: pointer;
}

td.badge-row {
  border-top: none !important;
  padding: 3px 5px 0px 0px !important;
  vertical-align: unset !important;
}

span.badge.analytics {
  width: 63px;
  float: unset;
  margin-top: 4px;
  vertical-align: top;
  /* margin-right: 200px; */
  padding: 3px 3px 3px 3px;
  background-color: #a9d15c;
  font-size: 60% !important;
  margin-left: 8px;
}

.sidebar.list-header {
  font-size: 0.88em !important;
  font-weight: 700 !important;
  color: #3c4045;
  text-transform: uppercase;
}

.sidebar.list-header.active {
  font-size: 0.88em !important;
  font-weight: 700 !important;
  color: white !important;
  text-transform: uppercase;
  background-color: #1d2a35;
  border-radius: 3px;
  margin: 0px 5px;
  padding: 10px;
}

a.active > .sidebar.list-header,
a.active > li.list-header {
  color: white !important;
  padding-left: 0px;
  list-style: none;
}

.list-group.analytics {
  /* max-height: 200px; */
  width: 100%;
  list-style-type: none;
  overflow: hidden;
  -ms-overflow-style: none;
}

.list-group.analytics.team-overview {
  max-height: 210px;
  width: 100%;
  list-style-type: none;
}

.list-group.analytics.team-overview > li.list-header {
  padding-bottom: 0px;
  padding-top: 0px;
}

.list-group.analytics > a > li.list-header,
.list-group.analytics > li.list-header {
  padding-bottom: 0px;
}

.list-group.analytics > a > li.list-header > p.item,
.list-group.analytics > li.list-header > p.item {
  font-weight: 500;
  margin-bottom: 0px;
  margin-left: 0px;
  display: inline-block;
}

p.item.lg {
  font-size: 15px;
}

img.article-attachment {
  max-height: 56px;
  max-width: 100px;
  /* margin: 0 8px 0 8px; */
}

div#breadcrumb-bar {
  margin-top: 50px;
  background-color: #274869;
  height: 50px;
  display: inline-block;
}

h3.directory.teams {
  color: #1d2a35 !important;
  font-size: 150%;
  font-weight: 600;
  margin-left: 0px;
  margin-bottom: 0px !important;
  display: inline-block;
}

button.btn.btn-primary.post-btn.onboarding.filter {
  display: inline-block;
  margin-left: 20px !important;
  margin-top: 31px !important;
  margin-right: 5px !important;
}

p.bulletin {
  color: #7c7c7c;
  font-weight: 600;
  font-size: 100%;
  line-height: 1.7;
  margin-top: 15px;
  margin-right: 17px;
  margin-left: 17px;
  margin-bottom: 8px;
}

h3.date.bulletin {
  color: #bcbcbc;
  font-weight: normal;
  font-style: normal;
  font-size: 100%;
  margin-left: 10px;
  margin-top: 12px !important;
  display: inline-block;
  float: right;
  margin-right: 12px;
  text-align: right;
}

.panel-body.bulletin {
  padding: 15px 20px 10px;
}

.panel.reports {
  background-color: #f6f6f6;
  border: 1px solid #ddd;
  height: 170px;
}

.panel.reports:hover {
  cursor: pointer;
}

.panel.reports.no-hover:hover {
  cursor: default;
}

.panel.reports:hover > .panel-body h3 {
  text-decoration: underline;
}

.panel.reports.no-hover:hover > .panel-body h3 {
  text-decoration: none;
}

.panel.reports > .panel-body h3 {
  color: #1d2a35;
  font-weight: 500;
  font-size: 16px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.panel.reports > .panel-body > i {
  color: #1d2a35;
  font-size: 20px;
  margin-top: 10px;
}

.bulletin-feed-post {
  border-bottom: 1px solid var(--zero-border-color);
  padding: 15px 0;

  &:first-child {
    padding-top: 0;
  }
}

.bulletin-post {
  /* max-height: 150px; */
  position: relative;
  overflow: hidden;
  background-color: white;
  color: #1d2a35;
  font-size: 13px;
}

.bulletin-post > p {
  color: #1d2a35;
  /* font-size: 13px; */
}

div.well.comments {
  margin-bottom: 0px;
  display: inline-block;
  width: 100%;
  background-color: #f6f6f6;
  color: #1d2a35;
  font-size: 12px;
  padding-top: 12px;
  padding-bottom: 12px;
}

div.well.feed {
  margin-bottom: 0px;
  display: inline-block;
  width: 100%;
  background-color: white;
  color: #1d2a35;
  font-size: 15px;
  border-width: 0px;
}

div.well.feed:hover {
  cursor: pointer;
}

.read-more {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  text-align: center;
  margin: 0;
  padding: 45px 0 0 0;
  color: #505050;

  /* "transparent" only works here because == rgba(0,0,0,0) */
  background-image: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 0.88) 68%,
    rgba(255, 255, 255, 1)
  );
}

h3.author.bulletin {
  font-size: 100%;
  margin-left: 0px;
  /* margin-top: 5px; */
  display: block;
  float: left;
  color: #1d2a35;
  word-break: break-word;
}

h3.author.bulletin.comments {
  font-size: 13px;
  margin-left: 0px;
  margin-top: 5px;
  display: block;
  float: left;
  color: #1d2a35;
  word-break: break-word;
  line-height: 1.3em;
}

h3.author.bulletin.date {
  margin-top: 0px !important;
  font-size: 85%;
  font-weight: 500;
  /* float: left; */
  color: #505050;
  margin-bottom: 0px !important;
}

h2.author.bulletin.date {
  margin-top: 0px !important;
  font-size: 80%;
  font-weight: 500;
  /* float: left; */
  color: #1d2a35;
  margin-bottom: 0px !important;
}

h2.author.bulletin.date:hover {
  cursor: default;
  text-decoration: none;
}

h2.author.bulletin.comment-date {
  font-size: 85%;
  font-weight: 500;
  /* float: left; */
  color: #505050;
  margin-bottom: 0px !important;
  line-height: 1;
}

h2.author.bulletin.comment-date:hover {
  cursor: default;
  text-decoration: none;
}

h3.author.bulletin.date.article {
  margin-top: 7px !important;
  font-size: 80%;
  font-weight: 600;
  float: right !important;
  margin-right: 0px;
  color: rgb(185, 185, 185) !important;
  margin-bottom: 0px !important;
}

h3.author.bulletin.date.article:hover {
  color: rgb(185, 185, 185) !important;
  cursor: text;
}

hr.bulletin {
  background-color: #fefefe !important;
  margin-top: 5px;
  margin-bottom: 5px;
}

div.vr {
  display: inline;
  height: 100%;
  width: 1px;
  border: 1px inset;
  margin: 5px;
  background-color: red;
}

div.bulletin-post-info {
  /* margin-top: -16px; */
  display: inline-block;
  /* float: right; */
  vertical-align: bottom;
}

div.bulletin-activity {
  display: inline-block;
}

div.bulletin-activity > a {
  /* color: #1D2A35; */
  display: inline-block;
  font-weight: 500;
  font-size: 90%;
}

p.views-count,
p.comments-count {
  display: inline-block;
  margin-bottom: 0px;
  margin-left: 3px;
}

i.add-responder {
  vertical-align: bottom;
  font-size: 26px;
  color: #1d2a35;
  cursor: pointer;
}

div.bulletin-text-editor
  > div.note-editor
  > div.note-toolbar-wrapper.panel-default {
  display: none;
}

li.active.page-item > a.page-link:hover {
  background-color: #03a9f4 !important;
}

li.page-item > a.page.link:focus {
  background-color: #03a9f4 !important;
}

li.page-item > a.page-link:hover {
  border-color: #03a9f4 !important;
  box-shadow: none !important;
}

.pagination > li:not(.disabled):not(.active) a:active {
  background-color: #03a9f4 !important;
  color: white;
}

p.views.bulletin {
  margin-top: 0px;
  color: #7a878e;
  font-weight: 500;
  display: inline-block;
  margin-left: 2px;
  margin-bottom: 0px !important;
  margin-right: 5px;
}

p.views.article {
  margin-top: 0px;
  font-size: 90%;
  color: #7a878e;
  font-weight: 500;
  display: inline-block;
  margin-left: 2px;
  margin-bottom: 0px !important;
}

p.comment.bulletin {
  margin-top: 7px;
  color: #7a878e;
  font-weight: 500;
  display: inline-block;
  margin-left: 2px;
  margin-bottom: 0px !important;
}

p.comment.bulletin.feed {
  margin-top: 0px;
  color: #7a878e;
  font-weight: 500;
  display: inline-block;
  margin-left: 2px;
  margin-bottom: 0px !important;
}

img.img-circle.img-sm.bulletin,
.default-avatar-circle.bulletin {

  &.extra-top-margin {
    margin-top: 5px;
  }

  margin-top: 2px;
  height: 26px;
  width: 26px;
}

.default-avatar-circle.bulletin > .initials {
  line-height: 26px;
  font-size: 10px;
  font-weight: bold;
}

img.img-org-logo {
  max-width: 115px;
  max-height: 65px;
}

h3.history.bulletin {
  color: #03a9f4;
  font-size: 90%;
  font-weight: 700;
  display: inline-block;
  float: right;
  margin-top: 12px;
  margin-right: 15px;
  cursor: pointer;
}

h3.history.bulletin:hover {
  color: #1f7da4;
}

img.radio {
  display: inline-block;
  margin-right: 8px;
  height: 10px;
  width: 10px;
}

h3.bulletin-title {
  color: #1d2a35 !important;
  font-size: 120%;
  font-weight: 700;
  margin-top: -1px;
  margin-left: 10px;
  float: left;
  display: relative;
  word-wrap: break-word !important;
  overflow-wrap: break-word !important;
  line-height: 2em;
  text-indent: 0px;
}

h3.bulletin-title:hover {
  color: #03a9f4 !important;
  cursor: pointer;
}

.panel-heading.bulletin {
  display: relative;
  position: relative;
  height: unset;
  min-height: 50px;
}

.btn.btn-tags {
  font-weight: 600 !important;
  font-size: 85%;
  border-radius: 20px;
  background-color: #e3e8ee;
  border-color: #e3e8ee !important;
  color: #1d2a35;
  float: left;
  display: inline-block;
  margin: 8px 10px 0 0;
  padding: 6px 12px 6px 12px;
}

button.btn.btn-tags.bulletin {
  font-size: 85%;
  margin-top: 2px !important;
  margin-bottom: 10px !important;
  margin-left: 0px !important;
  margin-right: 0px;
  padding: 6px 12px;
  float: right;
}

h2:hover:not(.not-a-link) {
  text-decoration: underline;
  cursor: pointer;
}

h2.bulletinTitle {
  font-size: 17px;
  margin-top: 7px;
}

h2.bulletinViewTitle {
  font-size: 17px;
  margin-top: 0px;
  color: #1d2a35;
  margin-bottom: 0px;
  line-height: 1.4em;
  word-break: break-word;
}

h2.articleTitle {
  font-size: 17px;
  margin-top: 7px;
  margin-bottom: 0px;
}

h2.big-analytics {
  font-size: 34px;
  font-weight: 400;
}

h2.big-analytics:hover {
  text-decoration: none;
  cursor: default;
}

.aside-risk.bulletin {
  font-weight: 600 !important;
  font-size: 90%;
  border-radius: 13px;
  border: 1px !important;
  border-style: solid !important;
  margin: 0px 10px 6px 0 !important;
  padding: 4px 8px 4px 8px !important;
  background-color: #ffffff !important;
  border-color: #e3e8ee !important;
}

.aside-risk.bulletin:hover {
  background-color: #e3e8ee !important;
  border-color: #e3e8ee !important;
}

.aside-risk.bulletin.NA.active {
  background-color: #e3e8ee !important;
  border-radius: 13px;
}

.aside-risk.bulletin.Low.active {
  background-color: #a9d15c !important;
  border-radius: 13px;
}

.aside-risk.bulletin.Medium.active {
  background-color: #fee379 !important;
  border-radius: 13px;
}

.aside-risk.bulletin.High.active {
  background-color: #f58768 !important;
  border-radius: 13px;
}

.aside-tag.bulletin {
  font-weight: 600 !important;
  font-size: 90%;
  border-radius: 10px;
  border: 1px !important;
  border-style: solid !important;
  background-color: #ffffff !important;
  border-color: #e3e8ee !important;
  color: #1d2a35;
  /* float: left;  */
  /* display: inline-block;  */
  margin: 0px 10px 6px 0 !important;
  padding: 4px 8px 4px 8px !important;
}

.aside-tag.bulletin:hover {
  /* color: #1D2A35 !important; */
  background-color: #e3e8ee !important;
  border-color: #e3e8ee !important;
}

.aside-tag.bulletin.active {
  /* color: #E3E8EE !important; */
  background-color: #e3e8ee !important;
  border-radius: 10px;
}

button.aside-tag.bulletin.active:hover {
  /* color: #E3E8EE !important; */
  background-color: #e3e8ee !important;
  border-radius: 10px;
}

h3.bulletin.filters {
  font-size: 100%;
  font-weight: 600;
  margin-top: 5px;
  margin-left: 5px;
  margin-bottom: 5px;
  display: block;
  word-wrap: break-word;
}

h3.bulletin.filters.active {
  color: #1d2a35 !important;
}

h3.bulletin.filters.active:hover {
  color: #1d2a35 !important;
}

.list-group.bg-trans.bulletin {
  margin-right: 20px;
}

.media-body.bulletin {
  margin-top: 5px !important;
}

.media-body.bulletin2 {
  margin-top: 1px !important;
}

.list-group.bg-trans.library {
  margin-top: 10px;
}

.media-body.page-header {
  width: unset !important;
  text-transform: unset !important;
}

h3.page-header {
  color: #1d2a35;
  text-transform: unset !important;
  font-weight: 600;
  font-size: 80%;
  margin-left: 0px;
  margin-top: -6px;
  display: block;
  margin-bottom: 0px;
}

h3.page-header.subheader {
  margin-top: 0px !important;
  font-weight: 600;
  font-size: 70%;
  float: left;
  color: rgb(170, 170, 170);
  padding-top: 0px;
  padding-bottom: 0px;
}

.btn.popover-profile {
  background-color: #03a9f4;
  border-color: #03a9f4;
  color: white;
  margin-right: -5px;
  margin-left: 10px;
}

.btn.popover-profile:hover {
  background-color: #1f7da4;
  border-color: #1f7da4 !important;
}

a.view-profile {
  font-weight: bold;
  color: #03a9f4 !important;
  text-decoration: underline;
}

.blue-link {
  color: var(--zero-light-blue) !important;
}

.red-link {
  color: var(--zero-delete-red) !important;
}

.blue-link, .red-link {
  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }

  &:disabled {
    color: var(--zero-grey) !important;
    cursor: not-allowed;
  }
}

div.inline {
  display: inline-block;
}

div.post-filters-container {
  float: left;
  margin-right: 8px;
}

span.circle {
  border-radius: 50%;
  width: 10px;
  height: 10px;
  margin-right: 4px;
  display: inline-block;
  /* width and height can be anything, as long as they're equal */
}

/* .ant-radio-button-wrapper {
	padding: 0 7px !important;
} */

.nav-header-panel p.header {
  padding-top: 3px;
}

p.header {
  color: #1d2a35;
}

#content-container:before {
  height: 250px;
}

.container-with-header {
  top: 60px;
  padding-bottom: 35px !important;
  padding-left: 0px !important;
  width: auto;
  max-width: 1100px;
  margin: 0 auto;
}

.main-content-container {
  margin-left: 250px;
}

.main-content-container-app {
}

.container-with-header-footer {
  top: 60px;
  padding-bottom: 35px !important;
}

span.header-post-button {
  border-radius: 5px;
  background-color: #d6b27e;
  color: white;
  padding: 6px 8px 6px 8px;
  text-decoration: unset;
  font-weight: 600;
  margin-right: 0px;
  font-size: 112%;
}

span.teams-buttons {
  background-color: #1d2a35;
  /* border-radius: 5px; */
  padding: 6px 10px 8px 6px;
  color: white;
  text-decoration: unset;
  font-weight: 600;
  margin-right: 0px;
  font-size: 112%;
}

span.teams-buttons:hover {
  color: white;
  /* background-color: #03a9f4; */
  /* border-bottom: 2px white solid; */
}

span.teams-buttons.active {
  /* background-color: #03a9f4; */
  color: white;
  text-decoration: unset;
  /* border-bottom: 2px white solid; */
}

span.teams-buttons.active:hover {
  /* background-color: #03a9f4; */
  color: white;
}

.breadcrumb li {
  text-decoration: unset !important;
}

div.dropdown-select-2 {
  margin-right: 10px;
}

input.form-control.manage-teams {
  border: 0px !important;
  width: 100%;
  background-color: #fff;
  border-radius: 4px;
  text-indent: 15px;
  font-weight: 500;
}

div.input-group.custom-search-form.manage-teams {
  width: 100%;
  border-color: #1d2a35;
  border-style: solid;
  border-width: 1px;
}

a.btn.btn-primary.post-btn.feed {
  margin-right: 22px !important;
}

a.btn.btn-primary.post-btn.add-users-to-team {
  display: block;
  width: 200px;
  margin: 0 auto !important;
  margin-top: 0px !important;
  float: none !important;
}

h3.create-team-header {
  color: white;
  margin-top: 18px;
  margin-bottom: 18px;
}

h3.section-titles {
  color: white;
}

h3.section-titles.admin-page-header {
  color: #1d2a35 !important;
}

h3.section-titles.team-name-header {
  color: #1d2a35 !important;
}

h3.section-titles.team-name-header:hover {
  cursor: pointer;
}

div.panel-margin-top-remove {
  padding-top: 5px !important;
}

h3.directory.teams.header {
  display: block;
  text-align: center !important;
  margin-top: 20px !important;
  margin-bottom: 10px !important;
}

h3.subtitle.header {
  display: block;
  text-align: center !important;
  margin-bottom: 5px;
}

button.add-team-sidebar {
  /* padding-top: 0px;
	padding-right: 0px;
	padding: unset !important; */
  padding: 0px 5px;
  display: inline-block !important;
  float: right !important;
  background-color: #fff;
  border-color: rgba(0, 0, 0, 0.07) !important;
  color: #7a878e;
  margin-top: -2px;
  -webkit-appearance: none;
}

.add-team-sidebar:not(.disabled):not(:disabled):hover:hover {
  box-shadow: unset !important;
}

.expand-button,
.expand-button:hover {
  color: #03a9f4 !important;
  box-shadow: unset !important;
}

span.mark-as-read {
  padding-top: 3px;
  font-weight: 500;
  cursor: pointer;
  color: #505050;
}

span.mark-as-read:hover {
  text-decoration: underline;
}

span.label.label-severity {
  /* margin-top: 10px;
    margin-bottom: 0px;
	border-radius: 3px;
	margin-top: 0px !important;
	font-size: 90%;
	padding: 4px 5px;
	color: white;
	display: inline-block;
	vertical-align: top; */

  margin-top: 0px !important;
  font-size: 90%;
  padding: 4px 5px;
  color: white;
  background-color: #f6f6f6;
  display: inline-block;

  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 180px;
  vertical-align: top;
}

/* span.label.label-severity:hover {
	cursor: pointer;
	filter: brightness(90%);
} */

span.label.label-severity.low {
  /* background-color: #FDE280 */
  background-color: #a9d15c !important;
}

span.label.label-severity.medium {
  /* background-color: #F3876C; */
  background-color: #fee379 !important;
}

span.label.label-severity.high {
  /* background-color: #EF645A */
  background-color: #f58768 !important;
}

/* span.label.label-shared {
	float: left;
    margin-bottom: 0px;
	background-color: #03a9f4;
	font-size: 90%;
	border-radius: 5px;
	display: inline-block;
	margin-left: 5px;
	padding-top: 5px;
} */

span.label.label-tag {
  margin-top: 0px !important;
  font-size: 90%;
  padding: 4px 5px;
  color: #505050;
  background-color: #f6f6f6;
  display: inline-block;

  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 180px;
  vertical-align: top;
}

/* span.label.label-tag:hover {
	background-color: rgb(225, 225, 225);
	cursor: pointer;
} */

div.tag-div {
  display: inline-block;
}

span.label.label-success.library {
  margin-right: 4px;
  margin-bottom: 7px;
  margin-top: 2px;
}

span.label.label-success.label-location {
  background-color: #8493a0;
  margin-right: 5px;
  font-size: 90%;
  padding: 4px 5px;
  display: inline-block;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 180px;
  vertical-align: top;
}

/* .btn-link.white {
	color: white !important;
	font-weight: 600;
	margin-left: 15px;
	float: left;
	margin-top: 0px;
} */

.media-body.account-card {
  padding-top: 0px !important;
}

.btn.fontrem {
  font-weight: unset !important;
}

span.text-lg.text-semibold.profile {
  margin-left: 3px;
  display: block;
}

span.text-md.text-semibold.profile {
  margin-left: 2px;
  color: #1d2a35;
}

p.text-sm.profile {
  margin-left: 2px;
  margin-bottom: 5px;
}

p.text-sm.profile.link {
  display: inline-block;
  text-decoration: underline;
}

p.text-sm.profile.link:hover {
  cursor: pointer;
}

p.text-sm.profile.link.delete-team {
  float: right;
  color: #ce1242;
}

p.text-sm.profile.link.confirm {
  /* color: #2FA59A; */
  color: #68c4fa;
}

p.text-sm.profile.link.cancel {
  /* color: #CE1242; */
  color: #68c4fa;
}

p.text-sm.profile.title {
  margin-bottom: unset !important;
  margin-left: 2px;
}

p.text-sm.profile.title.pending {
  color: #ce1242 !important;
}

p.notification-team {
  color: var(--zero-blue);
  margin-bottom: 0px;
  font-weight: 500;
  font-size: 11px;
  text-decoration: underline;
  word-break: break-all;
}

p.notification-title {
  color: var(--zero-blue);
  margin-bottom: 0px;
  font-weight: 600;
  font-size: 12px;
  word-break: break-word;
}

p.notification-author {
  color: #505050;
  margin-bottom: 0px;
  font-size: 11px;
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
  max-width: 15ch;
  text-overflow: ellipsis;
  vertical-align: top;
}

p.notification-date {
  color: var(--zero-dark-grey);
  margin-bottom: 2px;
  font-size: 12px;
  display: inline-block;
  vertical-align: text-top;
}

br.profile-space {
  line-height: 190%;
}

br.profile-space.not-admin {
  line-height: 250%;
}

p.badge.not-active {
  background-color: rgb(255, 255, 255);
  height: 1px;
  margin-bottom: -6px;
  width: 100%;
}

div.note-statusbar.locked {
  visibility: hidden;
}

div.pad-top-navigation {
  padding-top: 12px;
  margin-bottom: 5px;
}

.navbar-top-links > li > a.header-nav-button {
  padding: 0px !important;
}

a.footer-nav-button {
  display: inline-block;
  margin-left: 12px;
  margin-right: 12px;
}

a.footer-nav-button > span.teams-buttons.active:hover,
span.teams-buttons:hover {
  background-color: transparent;
}

span.footer-tabs {
  color: #505050;
  font-size: 85%;
  letter-spacing: -0.5px;
}

span.footer-tabs.active {
  color: #d6b27e !important;
}

a.footer-nav-button > span.teams-buttons {
  background-color: transparent;
  color: #505050;
  font-size: 135%;
}

a.footer-nav-button > span.teams-buttons.active {
  color: #d6b27e !important;
}

div.filter-menu {
  display: table;
  width: 100%;
  table-layout: fixed;
}

span.filter-item {
  vertical-align: top;
  display: table-cell;
}

span.filter-item.center {
  /* text-align: center; */
}

input.ant-input {
}

span.filter-item > div.ant-select {
  width: 100%;
}

/* span.filter-item > div.ant-select > .ant-select-selection--multiple .ant-select-selection__rendered {
	padding-right: 45px;
} */

div.members-search
  > div.ant-select
  > .ant-select-selection--multiple
  .ant-select-selection__rendered {
  padding-right: 65px;
}

/* span.filter-item > div.ant-select > .ant-select-selection--multiple {
	min-height: 33px;
	border-radius: 3px;
} */

div.fixed-sm-300.pull-sm-right {
  padding-left: 0px;
  padding-right: 0px;
}

div.fixed-sm-250.pull-sm-right.hidden-xs {
  margin-right: 10px;
}

div.fixed-sm-300.pull-sm-right {
  margin-right: 10px;
}

@media screen and (max-width: 770px) {
  div.fixed-sm-300.pull-sm-right {
    padding-left: 10px !important;
    padding-right: 10px !important;
    margin-right: 0px !important;
  }
}

small.text-muteds.account-teams {
  display: block;
}

div.filter {
  /* margin-top: 30px; */
  /* margin-left: 10px; */
}

/*
div.boxed {
	margin-bottom: 30px;
} */

p.onboarding-panel-box {
  width: 80%;
  margin: auto;
}

p.onboarding-subheader-invite {
  margin-left: 3px;
  margin-bottom: 5px !important;
}

p.small.onboarding-domains {
  margin-bottom: 5px !important;
  font-weight: bold;
}

div.panel.panel-light.panel-colorful.user-card-size {
  min-height: 85px !important;
  /* max-height: 80px; */
  margin-bottom: 10px;
}

input.form-control.topic-field {
  border-color: #d9d9d9;
  max-width: 300px;
}

input.form-control.incident-field {
  display: inline-block;
  width: calc(100% - 6rem);
  max-width: 300px;
  border-color: #d9d9d9;
}

.emoji-field {
  border-color: #d9d9d9;
}

.emoji-field:focus {
  /* outline: none !important; */
  border: 1px solid #d9d9d9;
}

.form-control.risk:focus {
  border-color: rgba(0, 0, 0, 0.07);
}

input.form-control.topic-field:focus {
  /* outline: none !important; */
  /* border:1px solid #47ABFC; */
}

div.ant-dropdown {
  z-index: 1200 !important;
}

div.ant-dropdown.ant-dropdown-placement-bottomCenter {
  max-width: 230px;
}

div.ant-upload.ant-upload-drag {
  margin-bottom: 10px;
}

i.anticon.anticon-cross {
  opacity: 1;
  font-size: 15px !important;
  font-weight: 600;
}

span.profile-avatar-upload
  > .ant-upload-list-picture-card
  .ant-upload-list-item {
  margin: 0 auto !important;
  float: none !important;
}

span.profile-avatar-upload > .ant-upload.ant-upload-select-picture-card {
  margin: 0 auto !important;
  float: none !important;
}

.bulletin-upload > .ant-upload.ant-upload-select-picture-card {
  width: 50px;
  height: 50px;
}

.bulletin-upload > .ant-upload.ant-upload-select-picture {
  width: 40px;
  height: 40px;
  border: 1px dashed #d9d9d9;

  border-radius: 4px;
  background-color: #fafafa;
  text-align: center;
  cursor: pointer;
  -webkit-transition: border-color 0.3s ease;
  -o-transition: border-color 0.3s ease;
  transition: border-color 0.3s ease;
  vertical-align: top;
  margin-right: 8px;
  margin-bottom: 8px;
  margin-top: 6px;
  display: table;
}

.bulletin-upload > .ant-upload.ant-upload-select-picture > .ant-upload {
  width: 100%;
  height: 100%;
  display: table-cell;
  text-align: center;
  vertical-align: middle;
  padding: 8px;
}

.bulletin-upload > .ant-upload-list-picture-card .ant-upload-list-item {
  width: 170px;
  padding: 0px !important;
}

.bulletin-upload
  > .ant-upload-list-picture-card
  .ant-upload-list-item-thumbnail {
  width: 100%;
  height: 110px !important;
}

.bulletin-upload
  > .ant-upload-list-picture-card
  .ant-upload-list-item-thumbnail
  > img {
  object-fit: contain;
  height: 93%;
}

div.ant-upload-list-picture-card
  > div.ant-upload-list-item.ant-upload-list-item-uploading
  > i.anticon.anticon-cross {
  display: none !important;
}

div.ant-upload-list-picture
  > div.ant-upload-list-item.ant-upload-list-item-uploading
  > i.anticon.anticon-cross {
  display: none !important;
}

span.profile-change-avatar {
  display: inline-block;
}

span.profile-change-avatar > div.ant-upload {
  display: block !important;
  margin: 0 auto !important;
  text-align: center;
}

.pager li:not(.disabled) > a:hover {
  border-color: #03a9f4;
  color: #03a9f4;
  box-shadow: none;
}

.pager li:not(.disabled) > a:active {
  border-color: #03a9f4;
  background-color: #03a9f4 !important;
}

/* .bulletin-upload>div.ant-upload.ant-upload-select.ant-upload-select-picture-card {
	margin: 0 auto;
	margin-top: -150px
} */

/* .bulletin-upload>div.ant-upload-list.ant-upload-list-picture-card{
	display: block;
	margin-top: 150px;
	padding-left: 10px;
} */

/* .bulletin-upload.normal>div.ant-upload-list.ant-upload-list-picture-card{
	display: block;
	margin-top: 0px !important;
	padding-left: 10px;
}  */

/* .bulletin-upload>.ant-upload-list-picture-card .ant-upload-list-item-info::before {
	right: 0px !important;
} */

span.org-logo-upload > .ant-upload-list-picture-card .ant-upload-list-item {
  width: 180px;
  /* height: 101px; */
}

span.org-logo-upload
  > .ant-upload-list-picture-card
  .ant-upload-list-item-thumbnail {
  width: 100%;
  height: 101px !important;
}

span.org-logo-upload
  > .ant-upload.ant-upload-select.ant-upload-select-picture-card {
  width: 180px;
  height: 101px;
}

span.org-logo-upload
  > .ant-upload.ant-upload-select.ant-upload-select-picture-card
  > span.ant-upload
  > div
  > .anticon.anticon-plus {
  display: none;
}

span.org-logo-upload
  > .ant-upload.ant-upload-select.ant-upload-select-picture-card
  > span.ant-upload
  > div
  > div.ant-upload-text {
  visibility: hidden;
}

span.org-logo-upload
  > .ant-upload.ant-upload-select.ant-upload-select-picture-card
  > span.ant-upload
  > div
  > div.ant-upload-text:after {
  content: "Add your logo...";
  visibility: visible;
  margin-left: -45px;
}

span.org-logo-upload
  > .ant-upload-list-picture-card
  .ant-upload-list-item-thumbnail
  > img {
  object-fit: contain;
  height: 88px;
}

div.ant-select-dropdown.kiosk-user-team-select > div {
  height: 100px;
}

div.user-select-dropdown-kiosk > .ant-select-selection--single {
  height: 40px;
}

div.user-select-dropdown-kiosk
  > .ant-select-selection--single
  > .ant-select-selection__rendered {
  line-height: 38px;
}

.ant-select.error .ant-select-selector {
  border-color: tomato !important;
  background-color: #fff5f5 !important;
}

.ant-select-selection-item,
.ant-select-selection-placeholder,
div.user-select-dropdown-kiosk
  > div.ant-select-selector
  > .ant-select-selection-placeholder {
  text-align: left;
}

div.user-select-dropdown.admins {
  width: 50%;
  display: block;
  margin: 0 auto;
}

.ant-select-item.ant-select-item-group {
  color: #1d2a35;
  font-weight: 500;
}

.ant-select-item-option:not(.ant-select-item-option-disabled) {
  color: #505050 !important;
}

.ant-select-selection-item {
  color: #505050 !important;
}

.ant-select-selection-item-content {
  color: #505050;
}

.ant-select-dropdown {
  -webkit-overflow-scrolling: touch !important;
}

.ant-dropdown-menu-item:not(.ant-dropdown-menu-item-disabled) {
  color: #505050;
}

.ant-select-selection,
.ant-select-dropdown-menu-item {
}

div.user-select-dropdown.ant-select.ant-select-enabled
  > .ant-select-selection--multiple
  .ant-select-selection__choice {
  background-color: #f6f6f6 !important;
  border-color: #e2e2e2 !important;
  color: #1d2a35 !important;
}

div.user-select-dropdown.ant-select.ant-select-enabled
  > .ant-select-selection--multiple
  .ant-select-selection__choice
  > span.ant-select-selection__choice__remove {
  /* color: #505050; */
  color: #1d2a35;
}

div.custom-bulletin.ant-select.ant-select-enabled
  > .ant-select-selection--single {
  border: 1px solid #d9d9d9;
  border-radius: 3px;
  font-size: 13px;
}

div.custom-bulletin.ant-select.ant-select-enabled.ant-select-focused
  > .ant-select-selection--single {
  border-color: #40a9ff;
}

div.custom-bulletin.ant-select.ant-select-enabled
  > .ant-select-selection--single
  > .ant-select-selection__rendered
  > .ant-select-selection__placeholder {
  color: #6b737c;
  opacity: 0.7;
}

.members-search
  > .ant-select.ant-select-enabled
  > .ant-select-selection--multiple
  .ant-select-selection__choice,
div.library-tags.ant-select.ant-select-enabled
  > .ant-select-selection--multiple
  .ant-select-selection__choice {
  background-color: #03a9f4 !important;
  border-color: rgb(42, 159, 209) !important;
  color: white !important;
}

.members-search
  > .ant-select.ant-select-enabled
  > .ant-select-selection--multiple
  .ant-select-selection__choice
  > span.ant-select-selection__choice__remove,
div.library-tags.ant-select.ant-select-enabled
  > .ant-select-selection--multiple
  .ant-select-selection__choice
  > span.ant-select-selection__choice__remove {
  /* color: white; */
  color: #1d2a35;
}

/* .ant-select-selection--multiple .ant-select-selection__choice__remove::before {
	content: '\f057' !important;
	font-family: "FontAwesome" !important
} */

div.library-tags.bulletin.ant-select.ant-select-enabled
  > div.ant-select-selection.ant-select-selection--multiple {
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}

.ant-select-dropdown-menu-item > div.LazyLoad {
  display: inline-block !important;
}

.ant-select-selection__choice__content > img.img-circle.dropdown {
  /* display: none; */
  width: 20px !important;
  height: 20px !important;
}

.ant-select-selection__choice__content > div.default-avatar-circle.dropdown {
  width: 20px !important;
  height: 20px !important;
  display: inline-block !important;
}

.ant-select-selection__choice__content
  > div.default-avatar-circle.dropdown
  > .initials {
  line-height: 20px !important;
  vertical-align: middle;
  font-size: 9px;
}

div.move-team-select.ant-select.ant-select-enabled {
  z-index: 99999999999999;
}

span.custom-range-picker > .ant-calendar-picker-input {
  padding-left: 5px !important;
  text-align: left;
}

.analytics-range-picker.ant-calendar-picker {
  width: 155px;
}

span.ant-calendar-picker {
  width: 200px;
}

th.ant-calendar-week-number-header:after {
  content: "Wk";
  font-weight: normal;
  line-height: 18px;
}

tbody.ant-calendar-tbody > tr > td.ant-calendar-week-number-cell {
  border-right: 1px solid #e8e8e8;
  cursor: default;
}

.ant-calendar-disabled-cell .ant-calendar-calendar-date {
  cursor: not-allowed;
  color: #bcbcbc;
  background: #f5f5f5;
  border-radius: 0;
  width: auto;
  border: 1px solid transparent;
}

.leading-indicator-select.ant-select.ant-select-enabled
  > .ant-select-selection.ant-select-selection--single
  > .ant-select-selection__rendered
  > .ant-select-selection-selected-value,
.period-select.ant-select.ant-select-enabled
  > .ant-select-selection.ant-select-selection--single
  > .ant-select-selection__rendered
  > .ant-select-selection-selected-value,
.month-select.ant-select.ant-select-enabled
  > .ant-select-selection.ant-select-selection--single
  > .ant-select-selection__rendered
  > .ant-select-selection-selected-value {
  padding-right: 10px;
}

.custom-placeholder:focus {
  outline: none !important;
  border: 1px solid #47abfc !important;
}

.custom-placeholder.comment {
  border: 0px solid transparent !important;
  outline: none !important;
}

.custom-placeholder::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #6b737c !important;
  opacity: 0.7; /* Firefox */
}

.custom-placeholder::-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #6b737c !important;
}

.custom-placeholder::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #6b737c !important;
}

div.tab-base > ul.nav.nav-tabs > li {
  margin-right: 2px;
}

div.tab-base > ul.nav.nav-tabs > li > button,
div.tab-base > ul.nav.nav-tabs > li > a {
  background-color: #f6f6f6;
  margin-right: 0px;
  padding: 8px;
  color: #8493a0;
  height: auto;
  font-weight: bold;
}

div.tab-base > ul.nav.nav-tabs > li.active {
  border: 1px solid #e2e2e2;
  border-bottom: 0px;
  border-radius: 2px;
}

div.tab-base > ul.nav.nav-tabs > li.active > button,
div.tab-base > ul.nav.nav-tabs > li.active > a {
  color: #1d2a35;
  background-color: white;
}

div.well.feed.create-post {
  color: #1d2a35;
  font-weight: 600;
}

div.well.feed.create-post:hover {
  color: #03a9f4;
}

.ant-checkbox-group.subscribers-checkbox > label.ant-checkbox-wrapper {
  display: block;
  margin-left: 0px;
  height: 35px;
}

label.ant-checkbox-wrapper {
}

.subscribers-checkbox {
  max-height: 165px;
  overflow: auto;
}

.subscribers-checkbox::-webkit-scrollbar {
  display: none;
}

/*
.alert.alert-info>div.ant-notification {
	top: 20px !important;
	margin-right: 22px !important;
}


.alert.alert-info>.ant-notification-notice-message {
	color: white !important;
	font-size: 14px !important;
	font-weight: 600 !important;
	padding-right: 5px !important;
	margin-bottom: 0px !important
}
.alert.alert-info>.ant-notification-notice-close {
	color: white !important;
}


.alert.alert-info>span.ant-notification-notice-btn {
	margin-top: 2px
}
.alert.alert-info>span.ant-notification-notice-btn:hover {
	cursor: pointer;
} */

.ant-notification.ant-notification-topRight {
  margin-right: 8px;
}

.ant-notification-notice-message {
  display: none !important;
}

.ant-notification-notice-description {
  color: white !important;
  font-size: 14px;
}

a.ant-notification-notice-close {
  color: white;
}

div.ant-radio-group.custom-radio {
  display: block !important;
  margin: 0 auto !important;
  width: 300px !important;
}

div.pac-container.pac-logo {
  z-index: 10000;
}

button.slick-arrow.slick-prev {
  left: 10px;
  z-index: 10000000;
}

button.slick-arrow.slick-next {
  right: 10px;
  z-index: 10000000;
}

/* button.slick-arrow.slick-prev {
	left: 10px;
	font-size: 1em !important;
	color: red !important;
}

button.slick-arrow.slick-next {
	right: 10px;
	font-size: 1em !important;
	color: white !important;
	content: "\2192" !important;

} */

.rh5v-DefaultPlayer_controls {
  right: 20px !important;
  left: 20px !important;
}

.strike {
  position: -webkit-sticky;
  position: -moz-sticky;
  position: -o-sticky;
  position: -ms-sticky;
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  display: block;
  z-index: 1;
  background: white;
  padding: 5px 5px;

  display: block;
  text-align: center;
  overflow: hidden;
  white-space: nowrap;
}

.strike > span {
  position: relative;
  display: inline-block;
}

.strike > span:before,
.strike > span:after {
  content: "";
  position: absolute;
  top: 50%;
  width: 500px;
  height: 1px;
  background: rgb(215, 215, 215);
}

.strike > span:before {
  right: 100%;
  margin-right: 15px;
}

.strike > span:after {
  left: 100%;
  margin-left: 15px;
}

.strike > span.date-divider {
  color: #1d2a35;
  font-weight: 550;
}

ul.control-dots {
  padding: 0px;
}

span.Linkify > p > a:hover {
  text-decoration: underline !important;
}

p.carousel-status {
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
}

div.react-viewer-mask {
  /* z-index: 1003 !important; */
  background-color: rgba(0, 0, 0, 0.92);
}

.react-viewer-transition {
  -webkit-transition: opacity 0.01s ease-out !important;
  transition: opacity 0.01s ease-out !important;
}

.modal-backdrop {
  z-index: 1001;
}

.react-pdf__Document {
  overflow: scroll;
}

label.note-form-label {
  color: #1d2a35 !important;
}

input.note-form-control.note-input:focus {
  border: 1px solid #47abfc;
}

label[disabled] {
  cursor: default;
}

label.form-check-label:hover,
label.form-radio-label:hover {
  cursor: pointer;
}

h3.section-titles.admin-section-header {
  margin-top: 2px;
}

div.custom-circle {
  width: 60px;
  height: 60px;
  border-radius: 30px;
  text-align: center;
  /* margin: 0 auto; */
  vertical-align: text-bottom;
}

div.custom-circle.on {
  background-color: #8bbd57;
}

div.custom-circle.off {
  background-color: #c54348;
}

label.ant-radio-wrapper:after {
  content: "" !important;
  display: block !important;
}

.thumb {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  padding-left: 0px;
}

.thumb li {
  width: 100px;
}

.overlay:hover {
  cursor: pointer;
}

.overlay {
  position: relative;
}

.overlay .thumbnail {
  display: block;
}

.overlay .playWrapper {
  opacity: 0;
  position: absolute;
  z-index: 1;
  top: 0;
  width: 100px;
  height: 100px;
  background: rgba(0, 0, 0, 0.5) url("../css/img/play-button.svg") no-repeat
    scroll center center / 40px 40px;
  border-radius: 2px;
}

.overlay .playWrapper.imgError {
  background-color: #000;
}

.overlay .playWrapper .small {
  width: 65px;
  height: 65px;
}

.overlay .playWrapper-new-edit-bulletin {
  opacity: 0;
  position: absolute;
  z-index: 0;
  top: 0;
  width: 168px;
  height: 102px;
  background: rgba(0, 0, 0, 0.5) url("../css/img/play-button.svg") no-repeat
    scroll center center / 40px 40px;
  border-radius: 0px;
}

.overlay .playWrapper-new-edit-article {
  opacity: 1;
  position: absolute;
  z-index: 0;
  top: 0;
  left: 0;
  width: 48px;
  height: 48px;
  background: rgba(0, 0, 0, 0.5) url("../css/img/play-button.svg") no-repeat
    scroll center center / 40px 40px;
  border-radius: 0px;
}

.playWrapper .playBtn {
  position: absolute;
  z-index: 2;
  width: 40px;
  height: 40px;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto; /* center */
}

.thumb .overlay .playWrapper,
.thumb .overlay .playWrapper-new-edit-bulletin,
.thumb .overlay .playWrapper-new-edit-article {
  opacity: 1;
}

.fadeIn {
  animation-name: fadeIn;
}

.loadingIcon {
  width: 15px;
  height: 15px;
  -webkit-animation: loadIcon 1.5s infinite;
  animation: loadIcon 1.5s infinite;
  transform-origin: 50% 50%;
  display: inline-block;
}

@keyframes loadIcon {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@-webkit-keyframes loadIcon {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.load3 .loader {
  font-size: 3.95652px;
  margin: 45px auto;
  text-indent: -9999em;
  width: 11em;
  height: 11em;
  border-radius: 50%;
  background: #177bbb;
  background: -moz-linear-gradient(
    left,
    #177bbb 10%,
    rgba(23, 123, 187, 0) 42%
  );
  background: -webkit-linear-gradient(
    left,
    #177bbb 10%,
    rgba(23, 123, 187, 0) 42%
  );
  background: -o-linear-gradient(left, #177bbb 10%, rgba(23, 123, 187, 0) 42%);
  background: -ms-linear-gradient(left, #177bbb 10%, rgba(23, 123, 187, 0) 42%);
  background: linear-gradient(to right, #177bbb 10%, rgba(23, 123, 187, 0) 42%);
  position: relative;
  -webkit-animation: load3 1.4s infinite linear;
  animation: load3 1.4s infinite linear;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
}

.load3 .loader:before {
  width: 50%;
  height: 50%;
  background: #177bbb;
  border-radius: 100% 0 0 0;
  position: absolute;
  top: 0;
  left: 0;
  content: "";
}

.load3 .loader:after {
  background: #ffffff;
  width: 68%;
  height: 68%;
  border-radius: 50%;
  content: "";
  margin: auto;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

@-webkit-keyframes load3 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes load3 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.spinner {
  margin: 0 auto;
  width: 100%;
  text-align: center;
}

.spinner.inverted > div {
  background-color: #1d2a35;
}

.spinner.inverted > div:hover {
  background-color: white !important;
}

.spinner > div {
  width: 12px;
  height: 12px;
  background-color: white;

  border-radius: 100%;
  display: inline-block;
  -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
  animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}

.spinner .bounce1 {
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}

.spinner .bounce2 {
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}

@-webkit-keyframes sk-bouncedelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
  }
}

@keyframes sk-bouncedelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

.draft-title-modal {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 350px;
}

div.signup-input {
  display: inline-block;
}

div.currently-viewing {
  margin-top: 0px;
}

.fixed-fluid > .fluid.mobile {
  padding-left: 0px;
  padding-right: 0px;
}

//custom styles for antd style
.account-edit-timezone-selector-wrapper {
  width: fit-content;
}

/*  MOBILE CSS  */
@media screen and (max-width: 320px) {
  label.account,
  div.signup-input.account,
  input.signup-field.custom-placeholder.account {
    text-align: center;
  }

  .ant-switch {
    min-width: 35px !important;
  }
  .form-control.account-name {
    max-width: 200px;
  }

  input.form-control.topic-field.account-name {
    max-width: 220px !important;
  }

  div.article-body-content > div > div > p > img {
    max-width: 300px !important;
    object-fit: contain;
  }
  div.article-body-content > div > div > p > iframe {
    max-width: 300px !important;
  }

  div.carousel.carousel-slider {
    max-height: 480px;
  }
  div.rh5v-DefaultPlayer_component {
    max-height: 480px !important;
  }

  img.bulletin-attachment-img-preview {
    height: 480px;
  }

  div.team-name-header {
    margin-top: 0px;
  }

  h3.section-titles.team-name-header {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    max-width: 260px;
  }

  div.admin-page-header {
    margin-top: 0px;
  }

  /* .navbar-top-links>li>a {
		height: 40px;
	} */

  /* .panel.bulletin-post-border  {
		border-width: 0px !important;
	} */

  p.item.lg.analytics-tag {
    max-width: 30ch;
  }

  p.leading-indicator-settings {
    margin-left: 112px !important;
  }

  .truncate.name {
    max-width: 15ch;
  }

  .truncate.email {
    max-width: 24ch;
  }

  .bulletin-upload > .ant-upload-list-picture-card .ant-upload-list-item {
    width: 135px !important;
  }

  h3.titles.onboarding.invite-link {
    font-size: 96%;
  }

  ul.nav.navbar-top-links.right-side > li > a {
    padding: 0 4px;
  }

  ul.nav.navbar-top-links.right-side > li > a > span {
    font-size: 12px !important;
    /* padding: 5px 8px 5px 8px */
  }

  ul.nav.navbar-top-links.right-side > li > a > i {
    font-size: 15px !important;
  }

  .header-nav-button > span.teams-buttons {
    padding: 5px 5px !important;
    font-size: 105% !important;
  }

  .truncate.name.long {
    max-width: 20ch;
  }

  h3.create-team-header {
    font-size: 16px;
    margin-top: 23px;
  }

  div.library-list {
    max-height: 340px !important;
  }

  .library-topics-mobile {
    max-height: 350px;
    overflow-y: scroll;
  }

  ul.ant-menu.org-switch-menu {
    width: 150px;
  }

  ul.ant-menu.org-switch-menu > li > p {
    max-width: 130px;
  }

  .nav-header-panel {
    padding: 12px;
  }

  h3.author.bulletin.comments {
    font-size: 80%;
  }

  h3.post-status {
    font-size: 80%;
  }

  /* p.post-comment {
		font-size: 83%;
	} */

  div.tab-base
    > ul.nav.nav-tabs
    > li
    > button
    div.tab-base
    > ul.nav.nav-tabs
    > li
    > a {
    padding: 6px;
    font-size: 90%;
  }

  .notifications-text {
    max-width: 180px;
  }

  .custom-circle {
    vertical-align: top !important;
  }
}

@media screen and (min-width: 321px) and (max-width: 413px) {
  label.account,
  div.signup-input.account,
  input.signup-field.custom-placeholder.account {
    text-align: center;
  }

  div.team-name-header {
    margin-top: 0px;
  }

  h3.section-titles.team-name-header {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    max-width: 300px;
  }

  div.article-body-content > div > div > p > img {
    max-width: 350px;
  }
  div.article-body-content > div > p > iframe {
    max-width: 350px;
  }

  div.carousel.carousel-slider {
    max-height: 570px;
  }
  div.rh5v-DefaultPlayer_component {
    max-height: 570px !important;
  }

  img.bulletin-attachment-img-preview {
    height: 570px;
  }

  /* .navbar-top-links>li>a {
		height: 40px;
	} */

  /* p.analytics-tag {
		width: 200px
	} */

  /* .panel.bulletin-post-border  {
		border-width: 0px !important;
	} */

  p.item.lg.analytics-tag {
    max-width: 25ch;
  }

  p.leading-indicator-settings {
    margin-left: 150px;
  }

  .form-control.account-name {
    max-width: 200px;
  }

  input.form-control.topic-field.form-control.account-name {
    max-width: 230px !important;
  }

  .bulletin-upload > .ant-upload-list-picture-card .ant-upload-list-item {
    width: 150px !important;
  }

  h3.titles.onboarding.invite-link {
    font-size: 115%;
  }

  .header-nav-button > span.teams-buttons {
    padding: 6px 8px !important;
  }

  .truncate.name {
    max-width: 20ch;
  }

  .truncate.name.long {
    max-width: 25ch;
  }

  h3.create-team-header {
    font-size: 16px;
    margin-top: 23px;
  }

  div.library-panel-height {
    /* max-height: 75vh; */
    /* height: auto; */
    height: 85vh;
  }
  /* ul.file-list {
		max-height: 48vh;
	} */

  div.library-list {
    max-height: 58vh !important;
  }

  .library-topics-mobile {
    max-height: 380px;
    overflow-y: scroll;
  }

  ul.ant-menu.org-switch-menu {
    width: 150px;
  }

  ul.ant-menu.org-switch-menu > li > p {
    max-width: 127px;
  }

  .nav-header-panel {
    padding: 12px;
  }

  h3.author.bulletin.comments {
    font-size: 85%;
  }

  h3.post-status {
    font-size: 85%;
  }

  /* p.post-comment {
		font-size: 85%;
	} */

  .draft-title-modal {
    max-width: 240px;
  }
}

@media screen and (min-width: 414px) and (max-width: 460px) {
  h3.section-titles.team-name-header {
    padding-top: 0px;
    display: inline-block !important;
    margin: 0 auto !important;
    color: #1d2a35 !important;

    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    max-width: 340px;
  }

  .draft-title-modal {
    max-width: 240px;
  }
}

@media screen and (min-width: 414px) and (max-width: 736px) {
  label.account,
  div.signup-input.account,
  input.signup-field.custom-placeholder.account {
    text-align: center;
  }

  div.post-filters-container {
    float: none;
  }

  div.media-right.more {
    margin-top: -25px;
  }

  aside#aside-container {
    padding-top: 0px !important;
  }

  a.thumbnail.onboarding {
    height: 155px;
  }

  img.img-responsive.onboarding.smaller {
    margin-top: 10px;
  }

  p.onboarding-panel-box {
    width: 95%;
  }

  /* .navbar-top-links>li>a {
		height: 40px;
	} */

  p.item.lg.analytics-tag {
    max-width: 35ch;
  }

  p.leading-indicator-settings {
    margin-left: 150px;
  }

  h3.create-team-header {
    font-size: 16px;
    margin-top: 23px;
  }

  h3.section-titles.admin-section-header {
    font-size: 16px;
    padding-top: 0px;
    display: inline-block !important;
    margin-top: 0px !important;
  }

  h3.section-titles.team-name-header {
    /* font-size: 16px; */
    padding-top: 0px;
    display: inline-block !important;
    margin: 0 auto !important;
    color: #1d2a35 !important;
  }

  /* .panel.bulletin-post-border  {
		border-width: 0px !important;
	} */

  div#page-content.no-padding-mobile,
  div#page-head.no-padding-mobile {
    padding-left: 0px !important;
    padding-right: 0px !important;
    padding-top: 10px !important;
  }

  div#page-content.no-padding-mobile-account {
    padding-left: 0px;
    padding-right: 0px;
  }

  .fixed-fluid > .fluid.mobile {
    padding-left: 0px;
    padding-right: 0px;
  }

  div.bottom-border-panel.account {
    width: 95%;
  }

  .ant-notification {
    max-width: calc(100vw - 50px) !important;
  }

  input.form-control.signup-field {
    margin: 0 auto;
  }

  div.country-phone-input-wrapper {
    width: 260px;
    margin: 0 auto;
  }

  div.currently-viewing {
    margin-top: 10px;
  }

  div.user-select-dropdown.admins {
    width: 100%;
  }

  input.form-control.topic-field.form-control.account-name {
    max-width: 230px !important;
  }

  div.article-body-content > div > div > p > img {
    max-width: 385px;
  }
  div.article-body-content > div > p > iframe {
    max-width: 385px;
  }

  .bulletin-upload > .ant-upload-list-picture-card .ant-upload-list-item {
    width: 150px !important;
  }

  h3.titles.onboarding.invite-link {
    font-size: 115%;
  }

  div.library-list {
    max-height: 60vh !important;
  }

  div.library-topics-mobile {
    max-height: 400px;
    overflow-y: scroll;
  }
}

/* LANDSCAPE MOBILE */
@media screen and (max-width: 736px) {
  div#aside {
    -webkit-overflow-scrolling: touch !important;
    scroll-behavior: smooth;
  }
  /* .navbar-top-links>li>a {
		height: 40px;
	} */

  h3.section-titles.team-name-header {
    /* font-size: 16px;	 */
  }
  h3.section-titles.admin-section-header {
    margin-top: 22px;
    font-size: 16px;
  }

  div.signup-input {
    display: block;
  }

  input.form-control.signup-field {
    margin: 0 auto;
  }

  div.country-phone-input-wrapper {
    width: 260px;
    margin: 0 auto;
  }

  aside#aside-container {
    padding-top: 0px !important;
  }

  div#page-content.no-padding-mobile,
  div#page-head.no-padding-mobile {
    padding-left: 0px !important;
    padding-right: 0px !important;
    padding-top: 10px !important;
  }

  div#page-content.no-padding-mobile-account {
    padding-left: 0px;
    padding-right: 0px;
  }

  .fixed-fluid > .fluid.mobile {
    padding-left: 0px;
    padding-right: 0px;
  }

  /* div.carousel.carousel-slider {
		height: 220px;
	} */

  /* img.attachment-img-sm.aspect-ratio {
		object-fit: cover;
		width: 100%;
		max-height: 220px;
		height: auto;
	} */

  div.user-select-dropdown.admins {
    width: 90%;
  }

  p.leading-indicator-settings {
    margin-left: 150px;
  }

  /* input,
	input[type='text'],
	input[type='number'],
	input[type="email"],
	input[type="password"],
	textarea,
	div.note-editable {
		font-size: 16px !important;
	} */

  /* input:not(.ant-select-search__field),
	textarea,
	div.note-editable {
		font-size: 16px !important;
		-webkit-appearance: none;
	}


	input.ant-select-search__field {
		font-size: 16px !important;
		box-sizing: content-box !important;
		padding-left: 5px !important;
		height: auto !important;
	} */

  div.bottom-border-panel.account {
    width: 85%;
  }

  div.filter-menu {
    display: block;
    width: 100%;
    table-layout: fixed;
  }
  span.filter-item {
    display: block;
    text-align: center !important;
  }

  span.filter-item > div.ant-select {
    width: 100%;
  }

  div.views-modal {
    overflow: scroll;
    margin-top: 8px;
    max-height: 300px;
  }

  iframe.note-video-clip {
    height: auto;
    width: auto;
  }

  .account-edit-timezone-selector-wrapper {
    margin: auto;
  }

  /* div.custom-circle {
		height: 50px;
		width: 50px;
		border-radius: 25px;
	} */
}

@media screen and (max-width: 767px) {
  div.library-list {
    max-height: 65vh !important;
  }

  div.library-topics-mobile {
    max-height: 400px;
    overflow-y: scroll;
  }

  .post-main-content-container {
    width: 100%;
    border-right: none;
    margin-right: 0px;
    padding-right: 0px;
  }

  div.post-main-content-feed {
    width: 100%;
    display: inline-block;
    max-height: 180px;
    /* overflow: scroll; */
  }

  div.post-details-content {
    width: 100%;
    display: inline-block;
    /* border-left: none; */
    border-top: 1px solid #ededed;
    vertical-align: top;
    margin-left: 0px;
    padding-left: 0px;
    padding-top: 10px;
    margin-top: 10px;
  }

  .col-reduce-padding-team:nth-child(odd) {
    padding-left: 0px;
    padding-right: 0px;
  }

  .col-reduce-padding-team:nth-child(even) {
    padding-left: 0px;
    padding-right: 0px;
  }

  span.header-post-button {
    font-size: 12px !important;
  }

  .notifications-text {
    max-width: 200px;
  }

  .custom-circle {
    vertical-align: top !important;
  }

  .panel.reports {
    height: 150px;
  }
}

/* Iphone Xs, Xr, Xs Max horizontal */
@media screen and (min-width: 720px) and (max-width: 850px) {
  div#page-content.no-padding-mobile,
  div#page-head.no-padding-mobile {
    padding-left: 0px !important;
    padding-right: 0px !important;
    padding-top: 10px !important;
  }
}

/* TABLET SIZE */
@media screen and (min-width: 737px) and (max-width: 1100px) {
  #container.mainnav-in #content-container:after {
    content: "";
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.75);
    z-index: 1102;
    transition: background-color 0.35s;
  }
  #container.mainnav-in #content-container {
    left: 0;
  }

  .col-lg-12.people_page {
    padding-left: 5px;
    padding-right: 5px;
  }
}

@media screen and (max-width: 1100px) {
  div#aside {
    -webkit-overflow-scrolling: touch !important;
    scroll-behavior: smooth;
  }

  #mainnav-container {
    position: fixed;
    -webkit-overflow-scrolling: touch !important;
    scroll-behavior: smooth;
  }

  div.pad-top-navigation.text-center.mar-top.mar-btm {
    margin-bottom: 30px;
  }

  a.header-nav-button {
    display: inline-block;
    margin-bottom: 18px;
  }

  .header-nav-button > span.teams-buttons {
    padding: 6px 10px 6px 10px;
    /* font-size: 125%; */
  }

  div.cls-content-lg.panel.white {
    width: auto;
  }

  span.bulletin-upload
    > div.ant-upload-list.ant-upload-list-picture-card
    > div.ant-upload-list-item.ant-upload-list-item-done
    > div.ant-upload-list-item-info::before {
    opacity: 1 !important;
  }

  span.bulletin-upload
    > div.ant-upload-list.ant-upload-list-picture-card
    > div.ant-upload-list-item.ant-upload-list-item-done
    > span.ant-upload-list-item-actions {
    opacity: 1 !important;
  }

  span.library-upload
    > div.ant-upload-list.ant-upload-list-picture-card
    > div.ant-upload-list-item.ant-upload-list-item-done
    > div.ant-upload-list-item-info::before {
    opacity: 1 !important;
  }

  span.library-upload
    > div.ant-upload-list.ant-upload-list-picture-card
    > div.ant-upload-list-item.ant-upload-list-item-done
    > span.ant-upload-list-item-actions {
    opacity: 1 !important;
  }

  span.library-upload > div.ant-upload.ant-upload-drag.ant-upload-disabled {
    display: none !important;
  }

  .navbar-content {
    margin-left: 0px;
  }

  div.currently-viewing {
    margin-top: 15px;
  }

  input:not(.ant-select-search__field):not([type="checkbox"]),
  textarea,
  div.note-editable {
    font-size: 16px !important;
    -webkit-appearance: none;
  }

  .ql-editor {
    font-size: 16px !important;
  }

  input.ant-select-search__field {
    font-size: 16px !important;
    /* box-sizing: border-box !important;*/
    /* padding: 0px !important; */
    /* height: auto !important; */
    width: 200px !important;
  }

  .user-select-dropdown-kiosk
    > div
    > div
    > div
    > div
    > input.ant-select-search__field {
    width: 100% !important;
  }

  .container-with-header {
    padding-left: 250px;
    width: auto;
    margin: auto;
  }

  .main-content-container {
    margin-left: 0px !important;
  }

  .error-screen-msg {
    width: 80%;
  }
}

i.description-icon {
  font-size: 15px;
}

@media screen and (min-width: 1100px) {
  i.description-icon:hover {
    color: #03a9f4 !important;
  }
}

@media screen and (min-height: 1023px) {
  img.bulletin-attachment-img-preview {
    height: 1023px;
  }
}

.col-xs-5ths,
.col-sm-5ths,
.col-md-5ths,
.col-lg-5ths {
  position: relative;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}

.col-xs-5ths {
  width: 20%;
  float: left;
}

.col-xs-6ths {
  width: 16.667%;
  float: left;
}

@media screen and (min-width: 768px) {
  .col-sm-5ths {
    width: 20%;
    float: left;
  }

  .col-sm-6ths {
    width: 16.667%;
    float: left;
  }
}

@media screen and (min-width: 992px) {
  .col-md-5ths {
    width: 20%;
    float: left;
  }

  .col-md-6ths {
    width: 16.667%;
    float: left;
  }
}

@media screen and (min-width: 1200px) {
  .col-lg-5ths {
    width: 20%;
    float: left;
  }

  .col-lg-6ths {
    width: 16.667%;
    float: left;
  }
}

@media screen and (max-width: 1100px) {
  .form-radio-input {
    -webkit-appearance: radio !important;
  }

  input[type="radio"] {
    -webkit-appearance: radio !important;
  }

  .settings-panel {
    right: 40px;
  }
}

@media screen and (min-width: 992px) {
  .settings-panel {
    width: 600px;
  }
}

@media screen and (min-width: 1100px) {
  .settings-panel {
    width: 680px;
    right: 50px;
  }
}

.settings-panel {
  -webkit-box-shadow: 0px 0px 8px 1px rgba(0, 0, 0, 0.2) !important;
  -moz-box-shadow: 0px 0px 8px 1px rgba(0, 0, 0, 0.2) !important;
  box-shadow: 0px 0px 8px 1px rgba(0, 0, 0, 0.2) !important;
}

ul.settings-list > a > li:hover:not(.list-header) {
  background-color: #fbfbfb;
  cursor: pointer;
}

ul.settings-list > a > li:not(.list-header) {
  padding-top: 5px;
  padding-bottom: 5px;
}

.ant-upload-list {
  margin-top: 0px !important;
}

.picture-offset-row {
  margin-top: 5px !important;
}

.ant-upload-list-item-uploading {
  margin-top: 5px;
}

.print-only {
  display: none;
}

/*
	Spaces
*/

.spaces-directory-user {
  cursor: pointer;
}

.spaces-directory-user:hover {
  background: #f5f5f5;
}

.spaces-directory-selected-user,
.spaces-directory-selected-user:hover {
  background: #e6f7ff;
  font-weight: 600;
}

.spaces-directory-disabled-user,
.spaces-directory-disabled-user:hover {
  cursor: not-allowed;
  background: unset;
  color: #8493a0;
}

.check-in-directory-border {
  padding-top: 20px;
  border-top: 1px solid #d9d9d9;

  @media screen and (min-width: 768px) {
    padding-top: 0;
    border-top: none;
    border-left: 1px solid #d9d9d9;
    min-height: 385px;
  }
}

.location-survey a {
  color: #03a9f4;

  &:hover {
    text-decoration: underline;
  }
}

#HW_badge_cont {
  position: absolute !important;
  top: 0px;
  left: 0px;
  width: 34px;
  height: 55px;
}

.HW_badge {
  left: 20px !important;
  top: 14px !important;
}

.HW_softHidden {
  opacity: 0 !important;
}

.v-divide {
  margin: 0 6px;
  border-left: 1px solid #ccc;
}

// react-multi-date-picker

.rmdp-header-values {
  font-size: 13px;
  font-weight: 600;
  color: #1d2a35 !important;
}

.rmdp-week-day {
  color: #03a9f4 !important;
}

.rmdp-day:not(.rmdp-disabled, .rmdp-day-hidden) span:hover {
  background-color: #77d5ff !important;
}

.rmdp-day.rmdp-selected span:not(.highlight) {
  background-color: #03a9f4 !important;
}

.rmdp-day.rmdp-today:not(.rmdp-selected) span:not(:hover) {
  background-color: transparent !important;
  color: #000 !important;
}

.no-box-shadow {
  box-shadow: none !important;
}

// Feed table
.feed-table-due-date input::placeholder {
  color: #505050 !important;
  opacity: 1;
}

.feed-table-due-date input:hover:not(:disabled) {
  text-decoration: underline;
}

// text overrides

.text-thin {
  font-weight: 400;
}

// flex stuff

.flex {
  display: flex;
}

.flex-col {
  display: flex;
  flex-direction: column;
}

.flex-align-center {
  align-items: center;
}

.flex-justify-center {
  justify-content: center;
}

.gap-sm {
  gap: 0.5rem;
}

.gap-md {
  gap: 1rem;
}

.gap-lg {
  gap: 1.5rem;
}

@media screen and (min-width: 768px) {
  .flex-row-sm {
    flex-direction: row;
  }
}

// Post analytics panels

@media screen and (min-width: 768px) {
  .post-analytics-semi-full-block {
    height: 610px;
  }

  .post-analytics-full-block {
    height: 610px;
  }

  .post-analytics-half-block {
    height: 300px;
  }
}

// Hover utils

.hover-parent {
  .show-on-hover {
    display: none;
  }

  &:hover {
    .show-on-hover {
      display: initial;
    }
  }
}

// Blue tags

.tag-blue {
  color: var(--zero-light-blue);
  border-color: var(--zero-light-blue);
  background-color: #2baadf1f;
}

.analytics-bottom-border {
  border-bottom: 1px solid var(--zero-border-color);
}

.ellipsis-2-lines {
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
  word-break: break-word;
}

.break-word {
  word-break: break-word;
  overflow-wrap: break-word;
}

img.signature {
  width: 100%;
  max-width: 500px !important;
  position: relative;
  display: block;
}

.ag-theme-custom {
  .ag-tooltip {
    // This is prevent showing background text when tooltip is shown
    background-color: #fff !important;
  }
}

.room-for-help-widget {
  margin-bottom: 3.5rem;
}

.html-text-area-output {
  line-height: 1.1;

  p {
    margin: 0;
  }
}

@import "./layouts.scss";
@import "./components.scss";
@import "./print.scss";
